import Dexie from 'dexie';
import { ExternalUser, Registration } from '/@features/overviewlist/overviewlist.types';
import { Project } from '/@features/project/project.types';
import {
  Checklist,
  ChecklistCategory,
  ChecklistCheckpoint,
  CheckpointFile,
} from '/@features/checklist2/checklist.types';

export type IFile = {
  id?: number;
  createdAt: Date;
  arrayBuffer: ArrayBuffer;
  /** Additional data will be sent as FormData */
  data: { [key: string]: string };
  name: string;
  uploadUrl: string;
};

export interface IJson {
  id?: number;
  createdAt: Date;
  data: any;
  name: string;
  uploadUrl: string;
}

class SyncQueue extends Dexie {
  files: Dexie.Table<IFile, number>;
  json: Dexie.Table<IJson, number>;

  constructor(name) {
    super(name);
    this.version(1).stores({
      files: '++id,createdAt,blob,name,uploadUrl',
    });
    this.version(2).stores({
      files: '++id,createdAt,arrayBuffer,name,uploadUrl',
    });
    this.version(3).stores({});
    this.version(4).stores({
      files: '++id,createdAt,name,uploadUrl',
      json: '++id,createdAt,name,uploadUrl',
    });
  }
}

class AppDb extends Dexie {
  checklistTemplates: Dexie.Table<Checklist, number>;
  checklistCategoryTemplates: Dexie.Table<ChecklistCategory, number>;
  checklistCheckpointTemplates: Dexie.Table<ChecklistCheckpoint, number>;
  checklists: Dexie.Table<Checklist, number>;
  checklistCategories: Dexie.Table<ChecklistCategory, number>;
  checklistCheckpoints: Dexie.Table<ChecklistCheckpoint, number>;
  checkpointFiles: Dexie.Table<CheckpointFile, number>;
  projects: Dexie.Table<Project, number>;
  externalUsers: Dexie.Table<ExternalUser, number>;
  registrations: Dexie.Table<Registration, number>;

  constructor(name) {
    super(name);

    const commonIndexes = [
      'checklistTemplateId',
      'checklistGuid',
      'createdAt',
      'createdByUserId',
      'updatedAt',
      'updatedByUserId',
    ];

    this.version(1).stores({
      checklists: `++id,statusId,${commonIndexes.join(',').replace('checklistGuid', '&checklistGuid')}`,
      checklistCategories: `++id,&categoryGuid,categoryTemplateId,${commonIndexes.join(',')}`,
      checklistCheckpoints: `++id,&checkpointGuid,checkpointTemplateId,categoryGuid,categoryTemplateId,${commonIndexes.join(',')}`,
      checkpointFiles: `++id,&fileGuid,checkpointGuid,fileControlId,${commonIndexes.join(',')}`,
    });

    this.version(2).stores({
      checklistTemplates: `++id,createdAt,&checklistTemplateId`,
      checklistCategoryTemplates: `++id,createdAt,checklistTemplateId,&categoryTemplateId`,
      checklistCheckpointTemplates: `++id,createdAt,checklistTemplateId,categoryTemplateId,&checkpointTemplateId`,
      checklists: `++id,statusId,${commonIndexes.join(',').replace('checklistGuid', '&checklistGuid')}`,
      checklistCategories: `++id,&categoryGuid,categoryTemplateId,${commonIndexes.join(',')}`,
      checklistCheckpoints: `++id,&checkpointGuid,checkpointTemplateId,categoryGuid,categoryTemplateId,${commonIndexes.join(',')}`,
      checkpointFiles: `++id,&fileGuid,checkpointGuid,fileControlId,${commonIndexes.join(',')}`,
    });

    this.version(3).stores({
      checklistTemplates: `++id,createdAt,&checklistTemplateId`,
      checklistCategoryTemplates: `++id,createdAt,checklistTemplateId,&categoryTemplateId`,
      checklistCheckpointTemplates: `++id,createdAt,checklistTemplateId,categoryTemplateId,&checkpointTemplateId`,
      checklists: `++id,statusId,${commonIndexes.join(',').replace('checklistGuid', '&checklistGuid')}`,
      checklistCategories: `++id,&categoryGuid,categoryTemplateId,${commonIndexes.join(',')}`,
      checklistCheckpoints: `++id,&checkpointGuid,checkpointTemplateId,categoryGuid,categoryTemplateId,${commonIndexes.join(',')}`,
      checkpointFiles: `++id,&fileGuid,checkpointGuid,fileControlId,${commonIndexes.join(',')}`,
      projects: `++id,&projectId,createdAt`,
    });

    this.version(4).stores({
      checklistTemplates: `++id,createdAt,&checklistTemplateId`,
      checklistCategoryTemplates: `++id,createdAt,checklistTemplateId,&categoryTemplateId`,
      checklistCheckpointTemplates: `++id,createdAt,checklistTemplateId,categoryTemplateId,&checkpointTemplateId`,
      checklists: `++id,statusId,${commonIndexes.join(',').replace('checklistGuid', '&checklistGuid')}`,
      checklistCategories: `++id,&categoryGuid,categoryTemplateId,${commonIndexes.join(',')}`,
      checklistCheckpoints: `++id,&checkpointGuid,checkpointTemplateId,categoryGuid,categoryTemplateId,${commonIndexes.join(',')}`,
      checkpointFiles: `++id,&checkpointFileGuid,fileGuid,checkpointGuid,fileControlId,${commonIndexes.join(',')}`,
      projects: `++id,&projectId,createdAt`,
    });

    this.version(5).stores({
      checklistTemplates: `++id,createdAt,&checklistTemplateId`,
      checklistCategoryTemplates: `++id,createdAt,checklistTemplateId,&categoryTemplateId`,
      checklistCheckpointTemplates: `++id,createdAt,checklistTemplateId,categoryTemplateId,&checkpointTemplateId`,
      checklists: `++id,statusId,${commonIndexes.join(',').replace('checklistGuid', '&checklistGuid')}`,
      checklistCategories: `++id,&categoryGuid,categoryTemplateId,${commonIndexes.join(',')}`,
      checklistCheckpoints: `++id,&checkpointGuid,checkpointTemplateId,categoryGuid,categoryTemplateId,${commonIndexes.join(',')}`,
      checkpointFiles: `++id,&checkpointFileGuid,fileGuid,checkpointGuid,fileControlId,${commonIndexes.join(',')}`,
      projects: `++id,&projectId,createdAt`,
      externalUsers: `++id,projectId,externalUserId`,
      registrations: `++localId,id,projectId,userId,externalUserId,from,to`,
    });
  }
}

export const syncQueue = new SyncQueue('sync_queue');
export const appDb = new AppDb('app');
