import { identity } from 'lodash-es';
import { Request } from './request.types';
import { statusColors } from '/@tools/general-utils';
import { GeometryType } from '/@types/geometry';
import { FeatureTypes } from '/@types/map';

export const priorityOptions = new Map([
  [100, 'Høy'],
  [200, 'Middels'],
  [300, 'Lav'],
]);

//request-map2
export enum Columns {
  StatusId = 'StatusId',
}

export enum Props {
  Vedlikeholdsansvarlig = 30,
  TilførselFraKurs = 35,
  TilførselFraTilknytningsskap = 36,
  KabelLinje = 62,
  TilførselFraKurs2 = 69,
  TilførselFraTilknytningsskap2 = 68,
  TilførselFraFotocelle = 91,
  TilførselFraKontaktor = 92,
  Fotocelle = 93,
  Tennpunktnummer = 182,
  Fotocelle2 = 202,
  Kontaktornummer = 203,
}

export enum IdType {
  MaintenanceObject = 1,
  Request = 2,
}

export function isOfKabel(typeProps: Array<[number, any]>, kabel: any) {
  return typeProps.some(([id, value]) => [Props.KabelLinje].includes(id) && value === kabel);
}

export const optKey = `${location.pathname.split('/')[1]}/opt`;

export const statusColorMap = new Map([
  [2010, statusColors[6]], // Avvent/vurderes
  [3000, statusColors[9]], // Opprettet
  [4000, statusColors[5]], // Under behandling
  [4500, statusColors[4]], // Avvik
  [5000, statusColors[6]], // Provisorisk rettet
  [6000, statusColors[1]], // Utført
]);

export const iconMap = new Map([
  [6, '\uf0eb'], // Elbelysningspunkt
  [7, '\uf0e7'], // Tilknytningsskap
  [12, '\uf7c0'], // Tennpunkt
]);

export const featureTypeMap = new Map([
  [6, FeatureTypes.Point], // Elbelysningspunkt
  [7, FeatureTypes.Point], // Tilknytningsskap
  [9, FeatureTypes.Cable], // Kabel
  [12, FeatureTypes.Point], // Tennskap
]);

export const featureTypeGeometryMap = new Map([
  [GeometryType.Point, FeatureTypes.Point],
  [GeometryType.LineString, FeatureTypes.Cable],
  [GeometryType.Polygon, FeatureTypes.Areas],
]);

export function joinId(idType: IdType, id: number): string {
  return `${idType}-${id}`;
}

export function splitId(combinedId: string): { idType: IdType; id: number } {
  const [idType, id] = combinedId.split('-').map((n) => Number(n));

  return {
    idType,
    id,
  };
}

export function viewToIdType(view: string) {
  switch (view) {
    case 'requests':
    case 'finished-requests':
      return IdType.Request;
    case 'report':
      return IdType.MaintenanceObject;
  }
}

export function getRequestName(request: Request, categories: Map<any, any> = new Map()) {
  return [request.id, categories.get(request.categoryId)?.name, request.address]
    .filter(identity)
    .join(' - ');
}
