declare var appConfig: any;

const apiArkivBaseUrl = import.meta.env.VITE_API_ARKIVURL;
const apiIoTBaseUrl = import.meta.env.VITE_API_IOTURL;
const apiBaseUrl = import.meta.env.VITE_API_BASEURL;
const buildVersion = import.meta.env.VITE_VERCEL_GIT_COMMIT_SHA;
const seqUrl = import.meta.env.VITE_SEQ_URL;
const seqKey = import.meta.env.VITE_SEQ_APIKEY;
const intercomAppId = import.meta.env.VITE_INTERCOM_APPID;
const oneSignalAppId = import.meta.env.VITE_ONESIGNAL_APPID;
const viewportString = 'width=device-width, initial-scale=1, viewport-fit=cover';
const viewportMapString = 'width=device-width, initial-scale=1, viewport-fit=cover, maximum-scale=1, user-scalable=no';

export default {
  apiArkivBaseUrl,
  apiIoTBaseUrl,
  apiBaseUrl,
  buildVersion,
  seqUrl,
  seqKey,
  intercomAppId,
  oneSignalAppId,
  viewportString,
  viewportMapString,
}

export {
  apiArkivBaseUrl,
  apiBaseUrl,
  apiIoTBaseUrl,
  buildVersion,
  intercomAppId,
  oneSignalAppId,
  seqKey,
  seqUrl,
  viewportMapString,
  viewportString,
}