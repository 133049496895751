export enum GeometryType {
  Point = 'Point',
  LineString = 'LineString',
  Polygon = 'Polygon',
}

export type GeometryCoordinatePoint = [longitude: number, latitude: number, altitude?: number];

export interface GeometryPoint {
  type: GeometryType.Point;
  coordinates: GeometryCoordinatePoint;
}

export interface GeometryLineString {
  type: GeometryType.LineString;
  coordinates: GeometryCoordinatePoint[];
}

export interface GeometryPolygon {
  type: GeometryType.Polygon;
  coordinates: GeometryCoordinatePoint[][];
}

export type Geometry = GeometryPoint | GeometryLineString | GeometryPolygon;
