import { computed, ref } from 'vue';
import { defineStore } from 'pinia';
import { api, createQueryParams, genericApiErrorHandler } from '/@tools/api';
import {
  DynamicProperty,
  FromApi,
  ImportProject,
  ImportProjectForm,
  LogOptions,
  Project,
  ProjectCount,
  ProjectCreateForm,
  ProjectFolder,
  ProjectLog,
  ProjectMO,
  ProjectOrder,
  ProjectRequest,
  ProjectStatus,
  ProjectStatusGroup,
  ProjectTodo,
  ProjectType,
  SystemJob,
} from './project.types';
import { compare } from '/@tools/intl';
import { StatusGroups } from '/@types/ids';
import { searchLatLng } from '/@tools/map';
import { setTime } from '/@tools/date-utils';
import {
  mapProjectHelper,
  mapProjectDetails,
  mapProjectFolder,
  mapProjectType,
  mapStatus,
  mapRequestHelper,
  mapLogHelper,
  mapOrder,
  mapImportProject,
  mapProjectMO,
} from './project.mappers';
import { Property } from '/@types/properties';
import { addDepartmentQueries } from '/@molecules/department-button/helpers';
import { Mention } from '/@features/notes/notes.types';
import { useOffline } from '/@composables';
import { Project2, mapProject } from './project.table';
import { AdminArticle } from '/@features/admin/admin.types';
import { mapArticle } from '/@features/admin/admin.mappers';
import { GenericAbortSignal } from 'axios';

export const useProjectStore = defineStore('project', () => {
  const projects = ref<Map<number, Project>>(new Map());

  type LoadProjectsOptions = {
    moduleId: number;
    statusGroupId?: number | null;
    useGoldPartners?: boolean;
    useDepartments?: boolean;
  };

  function loadProjects({
    moduleId,
    statusGroupId = null,
    useGoldPartners = false,
    useDepartments = true,
  }: LoadProjectsOptions) {
    let queries = createQueryParams(
      new Map([
        ['moduleId', moduleId],
        ['projectStatusGroupId', statusGroupId],
      ]),
      { useGoldPartners },
    );

    if (useDepartments) {
      queries = addDepartmentQueries(queries);
    }

    return api
      .get(`/v1/projects`, { params: queries })
      .then(({ data }) => {
        projects.value = new Map([
          ...projects.value,
          ...data.map((i: any) => [
            i.Id,
            mapProjectHelper(i, statusGroupId === StatusGroups.Avsluttede),
          ]),
        ]);
      })
      .catch(genericApiErrorHandler);
  }

  type LoadProjects2Options = {
    moduleId: number;
    statusGroupId: number;
    ref1?: string;
    name?: string;
    limit?: number;
  };

  function loadProjects2({
    moduleId,
    statusGroupId,
    ref1,
    name,
    limit,
  }: LoadProjects2Options): Promise<Map<number, Project2>> {
    const queries = createQueryParams(
      //@ts-expect-error issue with createQueryFn type
      new Map([
        ['moduleId', moduleId],
        ['statusGroupId', statusGroupId],
        ['referenceNo1', ref1],
        ['projectName', name],
        ['limit', limit],
      ]),
    );

    return api
      .get(`v2/projects?${queries}`)
      .then(({ data }) => {
        return new Map(data.map((p) => [p.Id, mapProject(p)]));
      })
      .catch(genericApiErrorHandler);
  }

  const offline = useOffline();

  function loadProjectById(projectId: number): Promise<Project> {
    return api
      .get(`/v1/projects/${projectId}`)
      .then(({ data }) => {
        const project = mapProjectHelper(
          data,
          data.ProjectStatus && data.ProjectStatus.Id === 2008,
        );
        projects.value.set(data.Id, project);
        return project;
      })
      .catch((err) => {
        if (err.code === 'ERR_NETWORK') {
          offline.database.get('projects').then((p) => {
            const project = p.find(({ id }) => id === projectId);
            if (!project) throw 'project not in favorites';

            projects.value.set(projectId, project);

            return project as Project;
          });
        }
        return genericApiErrorHandler(err);
      });
  }

  function createProject(form: ProjectCreateForm) {
    return api
      .post('/v1/projects', {
        Address: form.address,
        Municipality: form.municipality,
        Latitude: form.latitude,
        Longitude: form.longitude,
        Name: form.name,
        FolderId: form.folderId,
        ReferenceNo1: form.ref1,
        ReferenceNo2: form.ref2,
        Installer: form.installer,
        CaseworkerId: form.caseworkerId,
        FacilityId: form.facilityId,
        DueDate: form.dueDate ? setTime(form.dueDate) : null,
        Customer: form.customer,
        ProjectTypeId: form.typeId,
        Description: form.description,
        // ExecutionDate: Date,
        OverviewListEnabled: form.overviewListEnabled,
        StatusId: form.statusId,
        DepartmentId: form.departmentId,
        GridownerProjectName: form.gridownerProjectName,
        ContactId: form.contactId,
        ContactCompanyId: form.contactCompanyId,
        PriorityId: form.priorityId,
        // admin
        ColorId: form.colorId,
      })
      .catch(genericApiErrorHandler);
  }

  function updateProject(projectId: number, form: ProjectCreateForm) {
    return api
      .put(`/v1/Projects/${projectId}`, {
        Name: form.name,
        Description: form.description,
        ColorId: form.colorId,

        Installer: form.installer,
        CaseworkerId: form.caseworkerId,

        Address: form.address,
        Municipality: form.municipality,
        // MunicipalityNumber: form.municipalityNumber,
        Latitude: form.latitude,
        Longitude: form.longitude,

        ReferenceNo1: form.ref1,
        ReferenceNo2: form.ref2,

        FacilityId: form.facilityId,
        OverviewListEnabled: form.overviewListEnabled,
        FolderId: form.folderId === -1 ? null : form.folderId || null,
        DueDate: form.dueDate ? setTime(form.dueDate) : null,
        Customer: form.customer,

        GridownerProjectName: form.gridownerProjectName,
        DepartmentId: form.departmentId,

        ContactId: form.contactId,
        ContactCompanyId: form.contactCompanyId,

        PriorityId: form.priorityId,
      })
      .catch(genericApiErrorHandler);
  }

  function createProjectRaw(form) {
    return api
      .post(`v1/projects`, form)
      .then(({ data: projectId }) => projectId)
      .catch(genericApiErrorHandler);
  }

  function updateProjectRaw(projectId: number, form) {
    return api.put(`v1/projects/${projectId}`, form).catch(genericApiErrorHandler);
  }

  function toggleRegistrationLock(projectId: number, isLocked: boolean) {
    return api
      .post(`timer/registrations/${isLocked ? 'lock' : 'unlock'}/project/${projectId}`)
      .catch(genericApiErrorHandler);
  }

  function updateProjectType(projectId: number, type: { typeId: number; statusId: number }) {
    return api
      .put(`v1/projects/${projectId}/type`, {
        ProjectTypeId: type.typeId,
        ProjectStatusId: type.statusId,
      })
      .catch(genericApiErrorHandler);
  }

  async function copyProject(
    projectId: number,
    latlng: { lat: number; lng: number } | null = null,
  ) {
    await loadProjectById(projectId);
    const p = projects.value.get(projectId);
    if (!p) return;
    let location = null;
    if (latlng) {
      [location] = await searchLatLng({ lat: latlng.lat, lng: latlng.lng });
    }

    const form: ProjectCreateForm = {
      name: `${p.name} (kopi)`,
      typeId: p.type,

      description: p.description,
      installer: p.installer,
      caseworkerId: p.caseworkerId,
      customer: p.customer,
      gridownerId: p.gridownerId,
      projectFolderId: p.folderId,
      dueDate: p.dueDate,
      ref1: p.ref1,
      ref2: p.ref2,
      latitude: latlng?.lat,
      longitude: latlng?.lng,
      address: location?.address,
      municipalityNumber: location?.municipalityNumber,
      departmentId: p.departmentId,
    };

    return createProject(form);
  }

  function deleteProject(projectId: number) {
    return api
      .delete(`v1/projects/${projectId}`)
      .then(() => {
        projects.value.delete(projectId);
      })
      .catch(genericApiErrorHandler);
  }

  function newLoadProjectDetailsById(projectId: number): Promise<Map<number, Property>> {
    return api
      .get(`/v1/projects/${projectId}/properties`)
      .then(({ data: details }) => {
        return mapProjectDetails(details, projectId);
      })
      .catch(async (error) => {
        if (error.code === 'ERR_NETWORK') {
          console.warn('fetch from db');
          const pp = await offline.database.get('projectProperties');
          const a = pp.reduce((acc, cv) => {
            const { pId, id } = cv.id.split('-');
            if (pId === projectId) return acc;
            acc.set(id, cv);
          }, new Map());
          return a;
        }

        throw genericApiErrorHandler(error);
      });
  }

  function saveProjectDetails(
    projectId: number,
    detailPairs: Array<[number, { value: any; valueId: number | null }]>,
  ) {
    return api.put(
      `/v1/projects/${projectId}/properties`,
      detailPairs.map(([id, prop]) => ({
        Id: id,
        Value: prop.value,
        ValueId: prop.valueId,
      })),
    );
  }

  function loadPartnerOnProject(projectId: number): Promise<Map<number, { name: string }>> {
    return api.get(`v1/projects/${projectId}/partners`).then(({ data }) => {
      return new Map(
        data.map((p: FromApi) => {
          return [
            p.Id,
            {
              name: p.Name,
            },
          ];
        }),
      );
    });
  }

  function addPartnerToProject(
    projectId: number,
    partnerId: number,
    departmentId: number | null = null,
  ) {
    return api
      .post(`v1/projects/${projectId}/partners`, {
        PartnerId: partnerId,
        PartnerDepartmentId: departmentId,
      })
      .then(() => loadProjectById(projectId))
      .catch(genericApiErrorHandler);
  }

  function removePartnerFromProject(projectId: number, partnerId: number) {
    return api
      .delete(`v1/projects/${projectId}/partners/${partnerId}`)
      .then(() => loadProjectById(projectId))
      .catch(genericApiErrorHandler);
  }

  const projectFolders = ref<Map<number, ProjectFolder>>(new Map());

  function loadProjectFolders(
    { includeDisabled } = { includeDisabled: false },
  ): Promise<Map<number, ProjectFolder>> {
    return api
      .get(`/projectfolders`, { params: { includeDisabled } })
      .then(({ data }) => {
        const mappedFolders: Map<number, ProjectFolder> = new Map([
          ...projectFolders.value,
          ...data
            .filter(({ Disabled }) => (includeDisabled ? true : !Disabled))
            .map((folder: FromApi) => [folder.Id, mapProjectFolder(folder)])
            .sort((a: FromApi, b: FromApi) => compare(a[1].name, b[1].name)),
        ]);
        projectFolders.value = mappedFolders;

        return mappedFolders;
      })
      .catch(genericApiErrorHandler);
  }

  const projectCounts = ref<Map<number, ProjectCount>>(new Map());

  function loadProjectCounts(projectId: number) {
    return api
      .get(`/v1/project/${projectId}/counts`)
      .then(({ data }) => {
        projectCounts.value.set(projectId, {
          documents: data.Counts.Documents,
          files: data.Counts.Files,
          images: data.Counts.Images,
          notes: data.Counts.Notes,
          unresolvedNotes: data.Counts.UnresolvedNotes,
          requestNotes: data.Counts.RequestNotes,
          requests: data.Counts.Requests,
          unresolvedRequestNotes: data.Counts.UnresolvedRequestNotes,
          issues: data.Counts.Issues,
        });
      })
      .catch(genericApiErrorHandler);
  }

  const projectTypes = ref<Map<number, ProjectType>>(new Map());

  const projectTypesByTenantId = computed(
    () => (tenantId: number) =>
      new Map(
        [...projectTypes.value].filter(([id, { activeForTenant }]) =>
          activeForTenant.includes(tenantId),
        ),
      ),
  );

  function loadProjectTypes({
    moduleId,
    tenantId,
    useGoldPartners,
  }: {
    moduleId?: number | null;
    tenantId?: number | null;
    useGoldPartners?: boolean;
  }): Promise<Map<number, ProjectType>> {
    const queries = createQueryParams(new Map([['tenantIds', tenantId]]));
    return api
      .get(`/modules/${moduleId}/projectTypes?${queries}`)
      .then(({ data }) => {
        projectTypes.value = new Map([
          ...data
            .map((t): [number, ProjectType] => [t.Id, mapProjectType(t, moduleId, tenantId)])
            .sort((a, b) => compare(a[1].name, b[1].name)),
        ]);

        return projectTypes.value;
      })
      .catch(async (error) => {
        if (error.code === 'ERR_NETWORK') {
          const types = await offline.database.get('projectTypes');
          types.forEach((t) => {
            projectTypes.value.set(t.id, t);
          });

          return projectTypes.value;
        }
        throw genericApiErrorHandler(error);
      });
  }

  function editProjectType(projectId: number, projectType: { typeId: number; statusId: number }) {
    return api
      .put(`v1/projects/${projectId}/type`, {
        ProjectTypeId: projectType.typeId,
        ProjectStatusId: projectType.statusId,
      })
      .then(() => {})
      .catch(genericApiErrorHandler);
  }

  const statuses = ref<Map<number, ProjectStatus>>(new Map());

  const statusesByProjectType = computed(() => (projectTypeId: number | null) => {
    return new Map(
      [...statuses.value].filter(
        ([id, status]) =>
          status.projectTypeIds.includes(projectTypeId) &&
          !status.hiddenInProjectTypeIds.includes(projectTypeId) &&
          status.projectStatusGroupId !== StatusGroups.Avsluttede,
      ),
    );
  });

  function loadProjectStatuses({
    moduleIds,
    projectTypeIds,
    projectStatusGroupIds,
  }: {
    moduleIds?: number[];
    projectTypeIds?: number[];
    projectStatusGroupIds?: number[];
  }): Promise<Map<number, ProjectStatus>> {
    return api
      .get(`/v2/projects/statuses`, {
        params: createQueryParams(
          new Map([
            ['moduleIds', moduleIds],
            ['projectTypeIds', projectTypeIds],
            ['projectStatusGroupIds', projectStatusGroupIds],
          ]),
        ),
      })
      .then(({ data }) => {
        const mappedStatuses: Map<number, ProjectStatus> = new Map(
          data
            .map((status) => [status.Id, mapStatus(status, moduleIds || [])])
            .sort((a, b) => a[1].sortIndex - b[1].sortIndex),
        );
        statuses.value = mappedStatuses;
        return mappedStatuses;
      })
      .catch(genericApiErrorHandler);
  }

  function saveStatus(projectId: number, statusId: number) {
    return api
      .post(`/v1/projects/${projectId}/statusId`, {
        StatusId: statusId,
      })
      .then(() => {
        const project = projects.value.get(projectId);
        if (project) project.status = statusId;
      })
      .catch(genericApiErrorHandler);
  }

  const statusGroups = ref<Map<number, ProjectStatusGroup>>(new Map());

  function loadProjectStatusGroups() {
    return api
      .get('/v1/project/statusgroups')
      .then(({ data }) => {
        const mapped = data.map((group) => [
          group.Id,
          { name: group.Name, description: group.Description },
        ]);

        statusGroups.value = new Map([...statusGroups.value, ...mapped]);
      })
      .catch(genericApiErrorHandler);
  }

  function loadMentionsByProject(projectId: number): Promise<Map<number, Mention>> {
    return api
      .get(`/v1/projects/${projectId}/mentions`)
      .then(({ data }) => {
        return new Map(
          data.map((user) => [
            user.Id,
            {
              name: user.FullName,
              description: user.Description,
              autoMention: user.AutoMention,
            },
          ]),
        );
      })
      .catch(genericApiErrorHandler);
  }

  function loadSystemJobs(projectTypeId: number): Promise<Map<number, SystemJob>> {
    return api
      .get(`/ProjectTypes/${projectTypeId}/SystemJobs`)
      .then(({ data }) => {
        return new Map(
          data.map((systemJob: FromApi) => [
            systemJob.Id,
            {
              projectTypeId: systemJob.ProjectTypeId,
              name: systemJob.Name,
              projectStatuses: systemJob.ProjectStatuses.map((status: FromApi) => ({
                id: status.StatusId,
                name: status.StatusName,
                todoIds: status.TodoItemIds,
              })),
            },
          ]),
        );
      })
      .catch(genericApiErrorHandler);
  }

  const requests = ref<Map<number, ProjectRequest>>(new Map());

  function loadRequestsByProject(projectId: number) {
    console.warn('loadRequestsByProject - Should be in requests');
    return api
      .get(`/requests/project/${projectId}`)
      .then(({ data }) => {
        requests.value = new Map(data.map((req) => [req.Id, mapRequestHelper(req, projectId)]));
        return requests.value;
      })
      .catch(genericApiErrorHandler);
  }

  const projectTodoLists = ref<Map<number, Map<number, ProjectTodo>>>(new Map());

  function loadProjectTodos(projectId: number) {
    return api
      .get(`/Projects/${projectId}/Todos`)
      .then(({ data }) => {
        const list = new Map();

        data.forEach((item) => {
          list.set(item.Id, {
            todoItemId: item.TodoItemId,
            name: item.Name,
            checked: item.Checked,
            disabled: item.Disabled,
            updatedAt: item.UpdatedAt,
            updatedByUserId: item.UpdatedByUserId,
          });
        });

        projectTodoLists.value.set(projectId, list);
      })
      .catch(genericApiErrorHandler);
  }

  function saveArchived(projectId: number) {
    return api.put(`/v1/projects/${projectId}/finished`).then(() => {
      return loadProjectById(projectId);
    });
  }

  const logs = ref<Map<number, ProjectLog>>(new Map());

  function loadLogs({
    moduleId,
    caseworkerId,
    useGoldPartners = false,
    signal = undefined,
    useDepartments = true,
  }: {
    moduleId: number;
    caseworkerId?: number;
    useGoldPartners?: boolean;
    signal?: GenericAbortSignal;
    useDepartments?: boolean;
  }) {
    logs.value.clear();

    let queries = createQueryParams(
      new Map([
        ['limit', 500],
        ['moduleId', moduleId],
        ['caseworkerId', caseworkerId],
      ]),
      { useGoldPartners },
    );

    if (useDepartments) {
      queries = addDepartmentQueries(queries);
    }

    return api
      .get(`/projectlogs?${queries}`, { signal })
      .then(({ data }) => {
        data.forEach((log: FromApi) => {
          logs.value.set(log.Guid, mapLogHelper(log));
        });
      })
      .catch(genericApiErrorHandler);
  }

  function loadLogsByProject(projectId: number) {
    return api
      .get(`/projectlogs/project/${projectId}`)
      .then(({ data }) => {
        logs.value = new Map(data.map((log) => [log.Guid, mapLogHelper(log)]));
      })
      .catch(genericApiErrorHandler);
  }

  const downloadOrders = ref<Map<number, ProjectOrder>>(new Map());

  function loadProjectDownloadOrders(projectId: number) {
    return api.get(`projects/${projectId}/ziporders`).then(({ data }) => {
      data.forEach((order: any) => {
        downloadOrders.value.set(order.Id, mapOrder(projectId, order));
      });
    });
  }

  function deleteProjectDownloadOrder(projectId: number, orderId: number) {
    return api
      .delete(`projects/${projectId}/ziporders/${orderId}`)
      .then(() => {
        downloadOrders.value.delete(orderId);
      })
      .catch(genericApiErrorHandler);
  }

  const importProjects = ref<Map<number, ImportProject>>(new Map());

  function loadImportProjects({
    limit = 1000,
    processed = false,
    search = '',
  }: {
    limit?: number;
    processed?: boolean;
    search?: string;
  }) {
    return api
      .get(`/projectImports`, { params: { processed, limit, search } })
      .then(({ data }) => {
        data.forEach((project: FromApi) => {
          importProjects.value.set(project.Id, mapImportProject(project, processed));
        });
      })
      .catch(genericApiErrorHandler);
  }

  function createImportProject(form: ImportProjectForm) {
    return api
      .post(`/v1/projects`, {
        Name: form.projectName,
        ProjectImportIds: [...form.projectImportIds],
        ProjectTypeId: form.projectTypeId,
        CaseworkerId: form.caseworkerId,
        Installer: form.installer,
        Description: form.description,
        Customer: form.customer,
        ReferenceNo1: form.ref1,
        ReferenceNo2: form.ref2,
        Address: form.address,
        Municipality: form.municipality,
        Latitude: form.latitude,
        Longitude: form.longitude,
        FolderId: form.selectedProjectFolderId,
        DueDate: form.dueDate,
        ContactCompanyId: form.contactCompanyId,
        MunicipalityNumber: form.municipalityId,
        ContactId: form.contactId,
        DepartmentId: form.departmentId,
      })
      .then((result) => {
        [...form.projectImportIds].forEach((id) => {
          importProjects.value.delete(id);
        });
        return result;
      })
      .catch(genericApiErrorHandler);
  }

  function deleteImportProject(projectId: number) {
    return api
      .delete(`/projectImports/${projectId}`)
      .then((result) => {
        importProjects.value.delete(projectId);
        return result;
      })
      .catch(genericApiErrorHandler);
  }

  function connectImportProject(projectImportId: number, projectId: number) {
    return api
      .post(`projectImports/${projectImportId}/connectToProject/${projectId}`)
      .then(() => {
        importProjects.value.delete(projectImportId);
      })
      .catch(genericApiErrorHandler);
  }

  function triggerImportProjects() {}

  function updateImportProject(projectImportId: number, form: { ref1: string | null }) {
    return api
      .post(`projectimports/${projectImportId}/setreferenceno1`, { ReferenceNo1: form.ref1 })
      .then(() => loadImportProjects({}))
      .catch(genericApiErrorHandler);
  }

  function restoreImportProject(projectImportId: number) {
    return api
      .post(`/v2/ProjectImports/${projectImportId}/Restore`)
      .then(() => {
        const p = importProjects.value.get(projectImportId);
        if (p) p.processed = false;
      })
      .catch(genericApiErrorHandler);
  }

  // move to files ?
  function downloadPublicFile(guid: string) {
    return api
      .get(`publicFiles/${guid}`, {
        responseType: 'blob',
      })
      .then(({ data }) => data)
      .catch(genericApiErrorHandler);
  }

  function downloadExport(projectIds: number[]) {
    return api
      .post('/v1/projects/export', projectIds, {
        responseType: 'blob',
      })
      .then(({ data }) => {
        const anchor = document.createElement('a');
        anchor.href = URL.createObjectURL(data);
        anchor.download = 'excel_eksport';
        anchor.click();
      });
  }

  const tenantProjectProperties = ref<Map<number, DynamicProperty>>(new Map());

  function loadTenantProjectProperties(tenantId: number) {
    return api
      .get(`/Tenants/${tenantId}/ProjectTypeProperties`)
      .then(({ data }: { data: Array<FromApi> }) => {
        tenantProjectProperties.value = new Map(
          data.map((p) => [
            p.Id,
            {
              hiddenInProjectTypes: p.HiddenInProjectTypeIds,
            },
          ]),
        );
      })
      .catch(genericApiErrorHandler);
  }

  const articles = ref<Map<number, AdminArticle>>(new Map());

  function loadProjectArticles(projectId: number) {
    return api
      .get(`/Projects/${projectId}/Articles`)
      .then(({ data }: { data: Array<FromApi> }) => {
        articles.value = new Map(data.map((article) => [article.Id, mapArticle(article)]));
        return articles.value;
      })
      .catch(genericApiErrorHandler);
  }

  function addProjectArticles(
    projectId: number,
    articleIds: number[],
  ): Promise<Map<number, AdminArticle>> {
    return api
      .post(`/Projects/${projectId}/Articles`, {
        ArticleIds: articleIds,
      })
      .then(({ data }) => data)
      .catch(genericApiErrorHandler);
  }

  function loadProjectMaintenanceObjects(projectId: number): Promise<Map<number, ProjectMO>> {
    return api
      .get(`/Projects/${projectId}/MaintenanceObjects`)
      .then(
        ({ data }: { data: Array<FromApi> }) =>
          new Map(data.map((mo) => [mo.Id, mapProjectMO(mo)])),
      )
      .catch(genericApiErrorHandler);
  }

  type Gridowner = {
    id: number;
    name: string;
    tenantId: number;
    hasArchive: boolean;
  };

  function loadProjectOwnerByProject(projectId: number): Promise<Gridowner | null> {
    return api
      .get(`/v1/Projects/${projectId}/Gridowner`)
      .then(({ data }) => {
        if (!data) return null;

        return {
          id: data.GridownerId,
          name: data.Name,
          tenantId: data.TenantId,
          hasArchive: data.HasArchive,
        };
      })
      .catch(genericApiErrorHandler);
  }

  return {
    projects,
    loadProjects,
    loadProjects2,
    loadProjectById,
    createProject,
    updateProject,
    deleteProject,

    createProjectRaw,
    updateProjectRaw,

    toggleRegistrationLock,
    copyProject,
    updateProjectType,
    newLoadProjectDetailsById,
    saveProjectDetails,

    addPartnerToProject,
    removePartnerFromProject,

    importProjects,
    loadImportProjects,
    createImportProject,
    deleteImportProject,
    triggerImportProjects,
    connectImportProject,
    updateImportProject,
    restoreImportProject,

    projectFolders,
    loadProjectFolders,

    projectTypes,
    projectTypesByTenantId,
    loadProjectTypes,
    editProjectType,

    statuses,
    statusesByProjectType,
    loadProjectStatuses,
    saveStatus,

    statusGroups,
    loadProjectStatusGroups,

    projectCounts,
    loadProjectCounts,

    requests,
    loadRequestsByProject,

    projectTodoLists,
    loadProjectTodos,

    loadPartnerOnProject,

    logs,
    loadLogs,
    loadLogsByProject,

    loadSystemJobs,
    loadMentionsByProject,
    saveArchived,

    downloadOrders,
    loadProjectDownloadOrders,
    deleteProjectDownloadOrder,

    downloadPublicFile,
    downloadExport,

    tenantProjectProperties,
    loadTenantProjectProperties,

    articles,
    loadProjectArticles,
    addProjectArticles,

    loadProjectMaintenanceObjects,

    loadProjectOwnerByProject,
  };
});

// for use in table/map cmp only!

export function loadProjectStatuses({
  moduleIds,
  projectTypeIds,
  projectStatusGroupIds,
}: {
  moduleIds?: number[];
  projectTypeIds?: number[];
  projectStatusGroupIds?: number[];
}): Promise<Map<number, ProjectStatus>> {
  return api
    .get(`/v2/projects/statuses`, {
      params: createQueryParams(
        new Map([
          ['moduleIds', moduleIds],
          ['projectTypeIds', projectTypeIds],
          ['projectStatusGroupIds', projectStatusGroupIds],
        ]),
      ),
    })
    .then(({ data }) => {
      return new Map(
        data
          .map((status) => [status.Id, mapStatus(status, moduleIds || [])])
          .sort((a, b) => a[1].sortIndex - b[1].sortIndex),
      );
    })
    .catch(genericApiErrorHandler);
}

export function loadProjectFolders(
  { includeDisabled } = { includeDisabled: false },
): Promise<Map<number, ProjectFolder>> {
  return api
    .get(`/projectfolders`, { params: { includeDisabled } })
    .then(({ data }) => {
      return new Map(
        data
          .filter(({ Disabled }) => (includeDisabled ? true : !Disabled))
          .map((folder: FromApi) => [folder.Id, mapProjectFolder(folder)])
          .sort((a: FromApi, b: FromApi) => compare(a[1].name, b[1].name)),
      );
    })
    .catch(genericApiErrorHandler);
}

export function downloadProjectZipByProject(i) {
  const body = {
    ImageSizeName: i.imageSize,
    IncludeImagesInPdf: i.includeImagesInPdf,

    ChecklistTemplates: i.checklistTemplates,

    Photoreports: i.photoreportIds.map((id) => ({
      Id: id,
      IncludeImageFolder: true,
    })),

    ProjectDocumentGuids: i.projectFileGuids,

    IncludeProjectDocumentsFolder: i.includeProjectDocuments,
  };

  return api
    .post(`/projects/${i.projectId}/ziporders`, body)
    .then((res) => {})
    .catch(genericApiErrorHandler);
}
