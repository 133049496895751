import { computed, ref } from 'vue';
import { api, genericApiErrorHandler } from '../tools/api';
import { extractLastName } from '../tools/form';
import { compare } from '../tools/intl';

export type ContactCompany = {
  id: number;
  name: string;
  favorite: boolean;
  gridownerId: number | null;
  // are the rest in use?
  description: string;
  companyNumber: string;
  orgNumber: string;
  gridowner: string;
  ownedByTenantId: number;
  contacts: Array<{ id: number; name: string; email: string; phone: string; title: string }>;
  roles: Array<{ id: number; name: string }>;
  address: string;
  postalNumber: string;
  postalPlace: string;
  readonly deletedAt: Date | null;
};

export type Contact = {
  id: number;
  name: string;
  email: string;
  phone: string;
  title: string;
  companyId: number;
  company: string;
  contactNumber: string;
  roles: Array<{ id: number; name: string }>;
  readonly deletedAt: Date | null;
};

function mapContactCompany(res: any): ContactCompany {
  return {
    id: res.Id,
    name: res.Name,
    description: res.Description,
    companyNumber: res.CompanyNumber,
    orgNumber: res.OrgNumber,
    gridownerId: res.GridownerId,
    gridowner: res.Gridowner,
    ownedByTenantId: res.OwnedByTenantId,
    favorite: res.Favorite,
    contacts: res.CompanyContacts.sort((a: any, b: any) => compare(a.Name, b.Name)).map(
      (role: any) => ({
        id: role.Id,
        name: role.Name,
        email: role.Email,
        phone: role.Mobile,
        title: role.Title,
      }),
    ),
    roles: res.ContactRoles.map((role: any) => ({
      id: role.Id,
      name: role.Name,
    })),
    address: res.Address.Street,
    postalNumber: res.Address.PostalNumber,
    postalPlace: res.Address.PostalPlace,
    deletedAt: res.DeletedAt,
  };
}

function mapContactRoles(res: any) {
  return {
    id: res.Id,
    name: res.Name,
    isCompanySpecific: res.IsCompanySpecific,
    isContactSpecific: res.IsContactSpecific,
  };
}

function mapContact(res: any): Contact {
  return {
    id: res.Id,
    name: res.Name,
    email: res.Email,
    phone: res.Mobile,
    title: res.Title,
    companyId: res.ContactCompanyId,
    company: res.ContactCompanyName,
    contactNumber: res.ContactNumber,
    roles: res.ContactRoles.map((r: any) => ({
      id: r.Id,
      name: r.Name,
    })),
    deletedAt: res.DeletedAt,
  };
}

const contactCompanies = ref<Map<number, ContactCompany>>(new Map());
const contactRoles = ref(new Map());
const contacts = ref<Map<number, Contact>>(new Map());

export function useContacts() {
  function loadContactCompanies({ includeDeleted = false } = {}): Promise<
    Map<number, ContactCompany>
  > {
    const query = new URLSearchParams();

    if (includeDeleted) {
      query.append('includedeleted', 'true');
    }

    return api
      .get(`/contactcompanies?${query.toString()}`)
      .then(({ data }) => {
        const mapped = new Map(data.map((res: any) => [res.Id, mapContactCompany(res)]));
        contactCompanies.value = mapped;
        return mapped;
      })
      .catch(genericApiErrorHandler);
  }

  function createContactCompany(form) {
    return api
      .post('/contactcompanies', {
        Name: form.name,
        Description: form.description,
        OrgNumber: form.orgNumber?.replace(/\s*/g, ''), // remove any kind of whitespace
        ContactCompanyRoleIds: [...form.roleIds],
        GridownerId: form.gridownerId,
        Address: {
          Street: form.address,
          PostalNumber: form.postalNumber,
          PostalPlace: form.postalPlace,
        },
      })
      .then(({ data }) => data)
      .catch(genericApiErrorHandler);
  }

  function updateContactCompany(companyId, form) {
    return api
      .put(`/contactcompanies/${companyId}`, {
        Name: form.name,
        Description: form.description,
        OrgNumber: form.orgNumber?.replace(/\s*/g, ''), // remove any kind of whitespace
        ContactCompanyRoleIds: [...form.roleIds],
        GridownerId: form.gridownerId,
        Favorite: form.favorite,
        Address: {
          Street: form.address,
          PostalNumber: form.postalNumber,
          PostalPlace: form.postalPlace,
        },
      })
      .catch(genericApiErrorHandler);
  }

  function setContactCompanyFavorite(companyId, favorite) {
    return api
      .put(`/contactcompanies/${companyId}/favorite`, {
        IsFavorite: favorite,
      })
      .catch(genericApiErrorHandler);
  }

  function deleteContactCompany(companyId) {
    return api.delete(`/contactcompanies/${companyId}`).catch(genericApiErrorHandler);
  }

  function loadContacts({ includeDeleted = false } = {}) {
    const query = new URLSearchParams();

    if (includeDeleted) {
      query.append('includedeleted', 'true');
    }

    return api
      .get(`/contacts?${query.toString()}`)
      .then(({ data }) => {
        const mapped = new Map(data.map((res) => [res.Id, mapContact(res)]));
        contacts.value = mapped;
        return mapped;
      })
      .catch(genericApiErrorHandler);
  }

  function createContact(form) {
    const [LastName, FirstName] = extractLastName(form.name);

    return api
      .post(`/contacts`, {
        FirstName,
        LastName,
        Title: form.title,
        Email: form.email,
        Mobile: form.phone,
        ContactCompanyId: form.companyId,
        ContactRoleIds: [...form.roleIds],
      })
      .then(({ data }) => data)
      .catch(genericApiErrorHandler);
  }

  function updateContact(contactId, form) {
    const [LastName, FirstName] = extractLastName(form.name);

    return api
      .put(`/contacts/${contactId}`, {
        FirstName,
        LastName,
        Title: form.title,
        Email: form.email,
        Mobile: form.phone,
        ContactCompanyId: form.companyId,
        ContactRoleIds: [...form.roleIds],
      })
      .catch(genericApiErrorHandler);
  }

  function deleteContact(contactId) {
    return api.delete(`/contacts/${contactId}`).catch(genericApiErrorHandler);
  }

  function loadContactRoles() {
    return api
      .get('/contactroles')
      .then(({ data }) => {
        const mapped = new Map(data.map((res) => [res.Id, mapContactRoles(res)]));
        contactRoles.value = mapped;
        return mapped;
      })
      .catch(genericApiErrorHandler);
  }

  return {
    contactCompanies: computed(() => contactCompanies.value),
    loadContactCompanies,
    createContactCompany,
    updateContactCompany,
    deleteContactCompany,
    setContactCompanyFavorite,

    contacts: computed(() => contacts.value),
    loadContacts,
    createContact,
    updateContact,
    deleteContact,

    contactRoles: computed(() => contactRoles.value),
    loadContactRoles,
  };
}
