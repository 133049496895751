import { GeometryPoint } from '/@types/geometry';

export interface User {
  id: number;
  guid: string;
  auth0Id: string;
  email: string;
  name: string;
  firstName: string;
  lastName: string;
  birthdate: string;
  hmsCardNo: string;
  employeeNumber: string;
  phoneNumber: string;
  intercomIdentity: string;
  departmentId: number | null;
  department: string | null;
  signatureFileGuid: string;
  // Remove
  betaUser: boolean;
  showStatusNotificationPage: boolean;

  settings: {
    showVeilysProjectMapReportErrorMO: boolean;
    showEnergiProjectMapReportErrorMO: boolean;
    showElektroProjectMapReportErrorMO: boolean;
    showFinishedProjects: boolean;
    showStatusNotificationPage: boolean;
    betaUser: boolean;
  };

  tenant: {
    id: number;
    name: string;
    guid: string;
    municipalities: Array<string>;
    geometry: GeometryPoint;
    ekomAuthorizationNumber: string | null;
    departments: Map<number, string>;
    // is in use ????
    companyLogoGuid: string | null;
    address: string | null;
    postalNumber: string | null;
    postalPlace: string | null;
    phone: string | null;
    orgNumber: string | null;
    industries: Array<{
      id: number;
      name: string;
      description: string;
    }>;
  };

  roles: Array<{
    id: number;
    name: string;
  }>;

  modules: Array<{
    id: number;
    name: string;
  }>;

  permissions: string[];

  favoriteProjects: Map<
    number,
    {
      name: string;
      typeId: number;
      favoritedAt: Date;
    }
  >;

  favoriteProjectTypes: Map<number, string>;
  favoriteProjectFolders: Map<number, string>;
  hiddenModuleIds: Array<number>;
  // not implemented
  qualifications: Map<number, string>;
  // should not be used
  goldpartnerIds: Array<number>;
}

export type ProfileForm = {
  email: string | null;
  firstName: string | null;
  lastName: string | null;
  phoneNumber: string | null;
  birthdate: Date | null;
  hmsCardNumber: string | null;
  employeeNumber: string | null;
  qualificationIds: Array<number>;
  readonly departmentId: number | null;
};

export interface SettingsForm {
  showVeilysProjectMapReportErrorMO: boolean;
  showEnergiProjectMapReportErrorMO: boolean;
  showElektroProjectMapReportErrorMO: boolean;
  showFinishedProjects: boolean;
  betaUser: boolean;
  showStatusNotificationPage: boolean;
}

export enum RolePerm {
  AdminPageContracts = 'dd12a972-8e71-42cf-bbeb-aa964c9d6f82',
  AdminPageDynamicProperties = '2ad04c33-03e3-46ea-88e1-9baf5ac30e83',
  AdminPageErrorHandling = 'e944300f-0802-4f19-a71f-61a270301f97',
  AdminPageLabels = '72d0d5a9-e456-4377-85e4-bfe2fb8da853',
  AdminPageProjects = '449f2e31-8de3-43d9-931b-797821a2be72',
  AdminPageProjectCreate = 'ec51d06c-40f6-4650-8a7f-02b5ea44df9e',
  AdminPageRequestCategories = '701cebf3-2b83-4bd9-beb6-b154950dbef6',
  AdminPageUserGroups = '0951a9c5-c19c-4885-91ff-1cd88ca73c38',
  AdminPageQualifications = '69ccd6d9-dc29-4855-a1c1-de61cf626555',
  AdminPriorities = '0f64d7c7-1a62-4c37-aa1a-30c81f2d22f1',

  ArticlesDelete = '341af114-642a-431d-b2c1-e8e0019d97cf',

  ByggestromMeterCreate = 'a4357f0a-f7be-429e-be68-542f8ce8c834',
  ByggestromProductCreate = '924582e9-e8d3-464a-8136-62010528792d',
  ByggestromPowerboxCreate = '80eb6e88-bb7d-49d1-929b-0134e71079c4',
  ByggestromPowerboxExport = '4c0b5d41-3cc8-40ed-8eaa-7a1684022a38',

  ChecklistUnlock = '',

  EquipmentPageAdmin = '2aab80eb-2a22-40ef-9fcb-ae469615af4c',
  EquipmentUpdate = '33a16d2c-6367-4bfb-8ad2-3fcbb8a60644',
  EquipmentItemLoanDelete = 'cf23afd6-2e6e-4259-b38f-5b747bbd378f',

  MaintenancePageCondemned = '16d29e33-68ce-4c85-b89b-1a1ad4911e00',
  MaintenanceItemCreateOppdrag = '783c9b8e-6afd-4598-9452-a0ea1a5f3129',
  MaintenanceMapCreate = 'a46177c9-61c8-49e2-bd48-2dd183af3b9a',

  ProjectPageCreate = '2f7a604d-0f61-4a53-8597-c5b3551d5ec1',
  ProjectPageImport = 'c67ec0bc-2166-458c-8594-ffbe6633fa5c',
  ProjectPageDocumentationPortal = '66049700-23cd-4b82-ba91-2224f7ab16c4',
  ProjectPageStatusNotifications = '9aa84a62-8d9a-4ae6-83a5-d50f8a1e24a5',
  ProjectPageRequests = 'fc9f38ac-fb8c-48f6-8542-a441a28bc507',

  ProjectItemDownload = '3df46a2d-9448-4d93-a181-e6c828770a46',
  ProjectItemStatusAvslutt = 'eaa9157c-f5d1-42a6-b0c1-398bb3f231c5',
  ProjectItemReopen = '90a1481c-b132-430e-b4f8-28f5f5c64f57',
}
