import { computed, ref } from 'vue';
import { defineStore } from 'pinia';
import { api, genericApiErrorHandler } from '/@tools/api';

export const useColorStore = defineStore('color', () => {
  const colors = ref<Map<number, string>>(new Map());

  const getColor = computed(() => (colorId: number) => colors.value.get(colorId) || '#2c3033');

  function loadColors() {
    return api
      .get('colors')
      .then(({ data }) => {
        colors.value = new Map(data.map((c) => [c.Id, c.HexCode]));
        return colors.value;
      })
      .catch(genericApiErrorHandler);
  }

  return { colors, getColor, loadColors };
});
