import { sendError } from '/@plugins/sentry';
import { isSafari } from './general-utils';
import { toast } from '/@composables';

let thumbToast;

export function createImage(blob: Blob): Promise<HTMLImageElement> {
  const image = new Image();
  const url = URL.createObjectURL(blob);

  return new Promise((resolve, reject) => {
    image.src = url;
    image.onerror = reject;
    image.onload = () => {
      resolve(image);
      URL.revokeObjectURL(url);
    };
  });
}

export async function createSessionThumb(guid: string, blob: Blob): Promise<void> {
  try {
    const thumbBitmap =
      'createImageBitmap' in window && !isSafari()
        ? await createImageBitmap(blob)
        : await createImage(blob);
    const thumbCanvas = document.createElement('canvas');
    const thumbCtx = thumbCanvas.getContext('2d');
    const multiplier = thumbBitmap.width < 400 ? 1 : 400 / thumbBitmap.width;

    thumbCanvas.width = thumbBitmap.width * multiplier;
    thumbCanvas.height = thumbBitmap.height * multiplier;
    thumbCtx.drawImage(thumbBitmap, 0, 0, thumbCanvas.width, thumbCanvas.height);

    sessionStorage.setItem(`thumb-${guid}`, thumbCanvas.toDataURL('image/jpeg', 70));
  } catch (error) {
    if (error?.name === 'QuotaExceededError') {
      if (thumbToast == null) {
        thumbToast = toast(
          'Midlertidig minne er fult, og det kan derfor ikke genereres flere miniatyrbilder på enheten. Nye bilder vil derfor ikke kunne vises frem til Dokflyt har generert miniatyrbilder.',
        );
      }
    }

    sendError(error, { guid, error, isSafari: isSafari() });
  }
}

export function getSessionThumb(guid: string) {
  return sessionStorage.getItem(`thumb-${guid}`);
}

export function removeSessionThumb(guid: string) {
  sessionStorage.removeItem(`thumb-${guid}`);
}

/**
 * Gets the mimeType from a file, or defer it from extension if no type is present
 * @param file `File` like object: { name, type }
 * @returns mimeType
 */
export function mimeType(file: { name: string; type: string }) {
  if (file.type) return file.type;

  try {
    const [extension] = file.name.match(/\.[^.]+$/);

    switch (extension.toLowerCase()) {
      case '.heic':
        return 'image/heic';
      case '.msg':
        return 'application/vnd.ms-outlook';
      case '.pdf':
        return 'application/pdf';
      default: {
        sendError('Failed to get mimeType', { file });
        return '';
      }
    }
  } catch (error) {
    sendError(error);
    return '';
  }
}
