export enum LogType {
  Created,
  Updated,
  Deleted,
  NoteCreated,
  NoteUpdated,
  NoteDeleted, // Finnes ikke
  FileCreated,
  FileDeleted,
  LoanCreated,
  LoanDeleted,
  ChecklistCreated, // Finnes ikke
  ChecklistUpdated, // Finnes ikke
  ChecklistDeleted, // Finnes ikke
  Shared,
}

export function mapLogType(type) {
  switch (type) {
    case 'Add':
    case 'ble lagt til':
    case 'created':
      return LogType.Created;

    case 'ble oppdatert':
    case 'Update':
    case 'updated':
      return LogType.Updated;

    case 'Delete':
    case 'Deleted':
    case 'deleted':
      return LogType.Deleted;

    case 'noteCreated':
      return LogType.NoteCreated;

    case 'noteUpdated':
      return LogType.NoteUpdated;

    case 'fileAdded':
      return LogType.FileCreated;

    case 'fileDeleted':
      return LogType.FileDeleted;

    case 'loanCreated':
      return LogType.LoanCreated;

    case 'loanDeleted':
      return LogType.LoanDeleted;

    case 'Share':
      return LogType.Shared;

    default:
      return null;
  }
}
