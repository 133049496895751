import { ref } from 'vue';
import { useOneSignal } from '@onesignal/onesignal-vue3';
import { oneSignalAppId } from '/@tools/config';
import { awaitUser2, useUserStore } from '/@features/user';
import { hasRole } from '/@tools/general-utils';
import { storeToRefs } from 'pinia';
import { Role } from '/@types/ids';

let resolveOneSignal: Function;
export const awaitOneSignal = new Promise((resolve) => (resolveOneSignal = resolve));

export function useNotifications() {
  const oneSignal = useOneSignal();

  const permissionGiven = ref(false);
  const permissionGivenNative = ref('default');
  const optedIn = ref<boolean | undefined>(false);

  async function initializeOneSignal() {
    const { user } = storeToRefs(useUserStore());
    await awaitUser2;

    if (!hasRole(user.value, [Role.AppOwner])) return;

    return oneSignal
      .init({
        appId: oneSignalAppId,
      })
      .then(() => {
        resolveOneSignal();
      })
      .catch((error) => {
        console.error('OneSignal initialization error:', error);
      });
  }

  async function loginOneSignal(userId: string) {
    await awaitOneSignal;

    return oneSignal.login(userId).catch((error) => {
      console.error('OneSignal login error:', error);
    });
  }

  function logoutOneSignal() {
    return oneSignal.logout();
  }

  function updateState() {
    permissionGiven.value = oneSignal.Notifications.permission;
    permissionGivenNative.value = oneSignal.Notifications.permissionNative;
    optedIn.value = oneSignal.User.PushSubscription.optedIn;
  }

  function requestPermission() {
    return (
      !permissionGiven.value
        ? oneSignal.Notifications.requestPermission()
        : oneSignal.User.PushSubscription.optIn()
    ).then(() => {
      updateState();
    });
  }

  function optOut() {
    return oneSignal.User.PushSubscription.optOut().then(() => {
      updateState();
    });
  }

  return {
    permissionGiven,
    permissionGivenNative,
    optedIn,

    initializeOneSignal,
    loginOneSignal,
    logoutOneSignal,
    optOut,
    requestPermission,
    updateState,
  };
}
