import { Geometry } from "./geometry";

export enum FeatureTypes {
  Marker = 'marker',
  Point = 'point',
  Stack = 'stack',
  Cable = 'cable',
  Areas = 'area',
}

export interface Feature {
  /** Unique id that the map can reference when hover, clicking and marking features */
  id: string | number,

  /** Geometry of the Feature */
  geometry: Geometry,

  /** How to render the feature in the map. Should it be an outline, or a filled polygon? A circle, a pin or maybe stack more icons on top of eachother? */
  type: FeatureTypes,

  /** TODO */
  direction?: number,

  /** When hovering over a feature, or using the map function that always enables them, it shows a text next to the feature in the map */
  label?: string,

  /** When a point is marked as important, it becomes slightly larger when zoomed out than "normal" points */
  important?: boolean,

  /** The icon to displayed in points and markers */
  icon?: string,

  /** When stacking icons, provide an array of icon and alignment of them */
  stack?: {
    align: string,
    icons: Array<{
      image: string,
      label: string,
      iconTextFit?: boolean,
      base?: string,
    }>
  },

  /** Rotate the point in the map, including the icon */
  angle?: number,

  /** Color of the feature in the map. For features with icon, the icon stays white */
  color?: string,

  /** When used with a line or area, this makes the outline dashed */
  dashed?: boolean,

  /** Width of the line */
  width?: number,
}

export enum Mode {
  Select,
  MultiSelect,
  CreatePoint,
  CreateLineString,
  CreatePolygon,
}

export enum Layers {
  RoadsP = 'rp', // Privat
  RoadsK = 'rk', // Kommunalt
  RoadsF = 'rf', // Fylkesvei
  RoadsR = 'rr', // Riksvei
  RoadsS = 'rs', // Skogsvei?
  RoadsE = 're', // Europavei
  RoadsLK = 'rlk', // Lillehammer brøyteroder kommunalt
  RoadsLP = 'rlp', // Lillehammer brøyteroder ikke kommunalt
  NVDBRoads = 'nvdbroads',
  NVDBRoadsDir = 'nvdbroadsdir',
  Kartverket = 'kartverket',
  Kartverket2 = 'kartverket2',
}

export enum MapFunctions {
  /** Enables dragging points on the map */
  MoveFeatures,

  /** Enables copying points on the map */
  CopyFeatures,

  /** By preserving the drawing buffer, it's possible to export the canvas */
  PreserveBuffer,

  /** Always show labels on points instead of only on hover */
  LabelsAlwaysOn,

  /** Removes the searchbar when the filter menu is not open */
  HideSearch,
}

export enum Sources {
  Points = 'points',
  Points2 = 'points2',
  Cables = 'cables',
  Areas = 'areas',
  Roads = 'roads',
  RoadsL = 'roadsl', // Lillehammer brøyteroder
  Kartverket = 'kartverket',
  Kartverket2 = 'kartverket2',
}