import { Article, ArticleGroup } from './tenant.types';

function findCategory(productGroup: string): ArticleGroup {
  if (productGroup.includes('skap')) return 'powerbox';
  if (productGroup.includes('produkter')) return 'product';
  if (productGroup.includes('måler')) return 'meter';
  return 'other';
}

export function mapArticle(article: any): Article {
  return {
    category: findCategory(article.ProductGroupName),
    articleId: article.Id,
    name: article.Name,
    number: article.ArticleNumber,
    description: article.Description,
    combinedName: [article.ArticleNumber, article.Name, article.Description].join(' - '),
    unit: article.Unit,
  };
}
