<script lang="ts">
export const errorMessages = ref([]);
</script>

<script setup lang="ts">
import { computed, ref } from 'vue';
import { groupBy } from 'lodash-es';
import errorToast from './error-toast.vue';

const errors = computed(() => [...Object.entries(groupBy(errorMessages.value, 'status'))]);
</script>

<template>
  <div class="error-center">
    <error-toast v-for="[code, error] in errors" :key="code" :errors="error" :error-code="code" />
  </div>
</template>

<style scoped>
.error-center {
  position: fixed;
  bottom: var(--gap-sm);
  right: var(--gap-sm);
  z-index: 9999;

  display: grid;
  gap: var(--gap-sm);

  @media (max-width: 1000px) {
    left: var(--gap-sm);
    right: var(--gap-sm);
  }
}
</style>
