import { appDb } from './database';

/** WARNING: This function can be highly resource intensive on the server when used with a lot of projects */
export async function offlineOverviewProjects(data: Array<any>) {
  await appDb.projects
    .where('id')
    .noneOf(data.map((project) => project.ProjectId))
    .delete()
    .then((count) => {
      if (count > 0) console.log(`deleted ${count} projects from iDB`);
    });
}
