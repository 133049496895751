import { api, createQueryParams, genericApiErrorHandler } from '/@shared/tools/api';

export type Valuelist = {
  id: number;
  guid: string;
  name: string;
  folder: string;
  externalId: number;
  externalSource: string;
  valuelistItems: Map<number, ValuelistItem>;
  valuelistGuid: string | null;
  deleted: boolean;
  isTenantSpecific: boolean;
  projectTypes: Array<string>;
};

export type ValuelistItem = {
  id: number;
  name: string;
  valueId: number | null;
  valuelistId: number;
  valuelistGuid: string | null;
  sortIndex: number;
  externalId: number;
  externalSource: string;
  ownedByTenantId: number;
  folder: string;
  guidance: string;
  publicFileUri: string;
};

function mapValuelist(list: any, valuelistGuid = null): Valuelist {
  return {
    id: list.Id,
    guid: list.Guid,
    name: list.Name,
    folder: list.Folder,
    // used with NVDB
    externalId: list.ExternalId,
    externalSource: list.ExternalSource,

    valuelistItems: new Map(
      list.ValueListItems.map((obj) => [
        obj.Id || obj.ValueId,
        mapValuelistItem(obj, valuelistGuid),
      ]).sort((a, b) => a[1].sortIndex - b[1].sortIndex),
    ),
    valuelistGuid,

    deleted: list.Deleted,
    isTenantSpecific: list.TsTenantSpecific,
    projectTypes: list.ProjectTypes,
  };
}

function mapValuelistTemporary(list, valuelistGuid = null): Valuelist {
  return {
    id: list.Id,
    guid: list.Guid,
    name: list.Name,
    folder: list.Folder,
    // used with NVDB
    externalId: list.ExternalId,
    externalSource: list.ExternalSource,

    valuelistItems: new Map(
      list.ValueListItems.map((obj) => [obj.Id, mapValuelistItem(obj, valuelistGuid)]).sort(
        (a, b) => a[1].sortIndex - b[1].sortIndex,
      ),
    ),
    valuelistGuid,

    deleted: list.Deleted,
    isTenantSpecific: list.TsTenantSpecific,
    projectTypes: list.ProjectTypes,
  };
}

function mapValuelistItem(obj, valuelistGuid = null): ValuelistItem {
  return {
    id: obj.ValueId || obj.Id,
    name: obj.Name,
    valueId: obj.ValueId,
    valuelistId: obj.ValueListId,
    valuelistGuid,
    sortIndex: obj.SortIndex,
    // used with NVDB
    externalId: obj.ExternalId,
    externalSource: obj.ExternalSource,
    ownedByTenantId: obj.OwnedByTenantId,
    folder: obj.Folder,
    guidance: obj.Guidance,
    publicFileUri: obj.PublicFileUri,
  };
}

type LoadValuelistByGuidArgs = { valuelistGuid: string; partnerId?: number | null };

export function loadValuelistByGuid({
  valuelistGuid,
  partnerId = null,
}: LoadValuelistByGuidArgs): Promise<Valuelist> {
  const queries = createQueryParams(new Map([['partnerId', partnerId]]));
  return api
    .get(`/tenants/valuelists/${valuelistGuid}?${queries}`)
    .then(({ data }) => mapValuelist(data, valuelistGuid))
    .catch(genericApiErrorHandler);
}

// used by admin panel

function loadValuelists(): Promise<Map<number, Valuelist>> {
  return api
    .get(`/tenants/valuelists`)
    .then(
      ({ data: valuelists }) => new Map(valuelists.map((list) => [list.Id, mapValuelist(list)])),
    )
    .catch(genericApiErrorHandler);
}

function loadValuelistById(valuelistId: number) {
  return api
    .get(`/tenants/valuelists/${valuelistId}`)
    .then(({ data: valuelist }) => mapValuelistTemporary(valuelist))
    .catch(genericApiErrorHandler);
}

function createValuelistValue(valuelistId: number, form: { name: string; sortIndex: number }) {
  return api
    .post(`/tenants/valuelists/${valuelistId}/items`, {
      Name: form.name,
      SortIndex: form.sortIndex,
      // ExternalId: form.externalId,
      // ExternalSource: form.externalSource,
    })
    .catch(genericApiErrorHandler);
}

function deleteValuelistValue(valuelistId: number, valueId: number) {
  return api
    .delete(`/tenants/valuelists/${valuelistId}/items/${valueId}`)
    .catch(genericApiErrorHandler);
}

type UpdateValuelistValue = {
  name: string;
  sortIndex: number;
  externalId: string | null;
  externalSource: string | null;
};
function updateValuelistValue(valuelistId: number, valueId: number, form: UpdateValuelistValue) {
  return api
    .put(`/tenants/valuelists/${valuelistId}/items/${valueId}`, {
      Name: form.name,
      sortIndex: form.sortIndex,
      ExternalId: form.externalId,
      ExternalSource: form.externalSource,
    })
    .catch(genericApiErrorHandler);
}

export const AdminValuelist = {
  loadValuelists,
  loadValuelistById,
  createValuelistValue,
  deleteValuelistValue,
  updateValuelistValue,
};
