import { ref } from 'vue';

type ToastOptions = {
  /** Milliseconds of time before the toast dismisses */
  duration?: number;

  /** Does not timeout and have no close button: must be closed with close() */
  persistent?: boolean;

  /** Icon to be displayed in front of the message */
  icon?: string;

  /** Additional buttons */
  actions?: ToastAction[];
  /** changes background to red */
  danger?: boolean;
};

type ToastAction = {
  label: string;
  onClick: (toast: Toast) => void;
};

type Toast = {
  id: number;
  message: string;
  persistent: boolean;
  icon?: string;
  actions: ToastAction[];
  danger: boolean;
  close: () => void;
  text: (message: any) => void;
};

export const toastList = ref<Toast[]>([]);

let id = 0;

export function toast(
  message: string,
  { duration = 14_000, persistent = false, icon, actions = [], danger = false }: ToastOptions = {},
) {
  let timeout: number;
  const toastId = ++id;
  const toast = {
    id: toastId,
    message,
    persistent,
    icon,
    actions,
    danger,

    text(message: string) {
      const index = toastList.value.findIndex(({ id }) => id === toastId);

      if (index >= 0) {
        toastList.value.splice(index, 1, {
          ...toast,
          message,
        });
      }
    },

    close() {
      const index = toastList.value.findIndex(({ id }) => id === toastId);

      if (index >= 0) {
        toastList.value.splice(index, 1);
        clearTimeout(timeout);
      }
    },
  };

  toastList.value.push(toast);

  if (!persistent && duration !== Infinity) {
    timeout = setTimeout(() => {
      toast.close();
    }, duration);
  }

  return toast;
}
