import {
  AdminArticle,
  AdminArticleForm,
  ArticleType,
  DynamicProperty,
  FromApi,
  IntegrationError,
  ProjectFolder,
  TenantPublicFile,
  TenantRequestCategory,
  TermChecklistTemplate,
} from './admin.types';

export function mapArticle(a: FromApi): AdminArticle {
  return {
    id: a.Id,
    name: a.Name,
    articleNumber: a.ArticleNumber,
    description: a.Description,
    typeId: a.ArticleTypeId,
    typeName: a.ArticleTypeName,
    productGroupId: a.ProductGroupId,
    productGroupName: a.ProductGroupName,
    unitId: a.UnitId,
    unit: a.Unit,
    pricePerUnit: a.PricePrUnit,
    sortOrder: a.SortOrder,
    options: a.Options,
    hasLabel: a.hasOwnProperty('hasLabel') ? a.HasLabel : a.Options.includes('label'),
    hasTime: a.hasOwnProperty('hasTime') ? a.hasTime : a.Options.includes('time'),
    generatedExternally: a.GeneratedExternally,
    relatedArticleId: a.RelatedArticleId,
    labels: new Set(a.Labels),
  };
}

export function mapArticlesForm(form: AdminArticleForm) {
  return {
    Name: form.name,
    ArticleNumber: form.articleNumber,
    Description: form.description,
    ArticleTypeId: form.typeId,
    UnitId: form.unitId,
    PricePrUnit: form.pricePerUnit ? Number(form.pricePerUnit) : 0,
    SortOrder: form.sortOrder ? Number(form.sortOrder) : null,
    HasLabel: form.hasLabel,
    HasTime: form.hasTime,
    RelatedArticleId: form.relatedArticleId,
    Labels: [...form.labels],
  };
}

export function mapArticleType(a: FromApi): ArticleType {
  return {
    id: a.Number,
    name: a.Name,
    productGroupId: a.ArticleProductGroupId,
    productGroupName: a.ArticleProductGroupName,
    units: a.Units.map((u: FromApi) => ({
      id: u.Id,
      name: u.Name,
    })),
  };
}

export function mapProjectFolder(folder: FromApi): ProjectFolder {
  return {
    id: folder.Id,
    name: folder.Name,
    geometry: folder.Geometry,
    enabled: !folder.Disabled,
    departmentId: folder.DepartmentId,
    department: folder.DepartmentName,
    contactCompanyId: folder.ContactCompanyId,
    contactCompanyName: folder.ContactCompanyName,
    externalContractNumber: folder.ExternalContractNumber,
    externalContractItem: folder.ExternalContractItem,
    folderNumber: folder.FolderNumber,
  };
}

export function mapDynamicProperty(
  property: {
    Id: number;
    Name: string;
    HiddenInProjectTypeIds: number[];
    ProjectTypes: { Id: number }[];
    SortIndex: number;
  },
  moduleIds: number[],
): DynamicProperty {
  return {
    id: property.Id,
    name: property.Name,
    hiddenInProjectTypeIds: property.HiddenInProjectTypeIds,
    projectTypeIds: property.ProjectTypes.map(({ Id }) => Id),
    sortIndex: property.SortIndex,
    moduleIds,
  };
}

export function mapIntegrationError(error: FromApi): IntegrationError {
  const { InvoiceLine: invoiceLine, InvoiceNo } = JSON.parse(error.JsonData);

  return {
    id: error.Id,
    projectId: error.ProjectId,
    invoiceOrderId: invoiceLine.orderId,
    invoiceNo: InvoiceNo,
    invoiceName: invoiceLine.name,
    invoiceProductId: invoiceLine.productId,
    invoiceType: invoiceLine.type,
    invoiceTypeFormatted:
      invoiceLine.type === 'M'
        ? 'Materiell'
        : invoiceLine.type === 'P'
          ? 'Produkt'
          : error.invoiceType,
    createdByUserId: error.CreatedByUserId,
    createdAt: new Date(error.CreatedAt),
    errorDescription: error.ErrorDescription,
  };
}

export function mapTermChecklistTemplates(checklist: FromApi): TermChecklistTemplate {
  return {
    name: checklist.ChecklistName,
    templateId: checklist.ChecklistTemplateId,
    category: checklist.ChecklistCategoryName,
    options: checklist.Options.map((o: any) => ({
      id: o.OptionId,
      name: o.OptionName,
      termGuids: o.OptionTermGuids,
    })),
  };
}

export function mapTenantPublicFile(file: FromApi): TenantPublicFile {
  return {
    guid: file.FileGuid,
    id: file.Id,
    name: file.Name,
    description: file.Description,
    version: file.Version,
    type: file.Extension,
    uploadedAt: new Date(file.UploadedAt),
    uploadedBy: file.UploadedByUserName,
  };
}

export function mapTenantRequestCategory(tenant: FromApi): TenantRequestCategory {
  return {
    id: tenant.Id,
    tenantId: tenant.TenantId,
    tenantName: tenant.Name,
    requestCategoryId: tenant.RequestCategoryId,
    requestCategoryName: tenant.RequestCategoryName,
    createProject: tenant.CreateProjectFromRequest,
    sendNotifications: tenant.SendNotifications,
    departmentId: tenant.DepartmentId,
    favoriteUsers: new Map(
      tenant.FavoriteUsers.map((user: any) => [
        user.UserId,
        {
          userId: user.UserId,
          fullName: user.FullName,
          autoEmail: user.AutoEmail,
          autoMention: user.AutoMention,
        },
      ]),
    ),
  };
}
