import { SimpleFormField } from '/@molecules/simple-form/simple-form.types';

export function getValidation(formFields: SimpleFormField<any>[]) {
  return validateForm(
    formFields
      .filter((i) => (i.visible !== undefined ? i.visible : true))
      .map(({ validation }) => validation || [])
      .filter((i) => i.length > 0)
      .flat(),
  );
}

export type ValidationRule = [boolean, string];
type ValidationResult = { ok: boolean; message: string | null };

export function validateForm(validation: Array<ValidationRule>): ValidationResult {
  const fault = validation.find(([rule]) => rule);

  if (fault != null) {
    return {
      ok: false,
      message: fault[1],
    };
  }

  return {
    ok: true,
    message: null,
  };
}

export function emailValidation(email: string) {
  const regex =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  return regex.test(email.trim());
}

export function orgNumberValidation(value: string) {
  const trimmedValue = value.replace(/\s*/g, '');

  if (trimmedValue.length !== 9) return false;

  const numbers = trimmedValue.split('').map((n) => Number(n));
  const multipliers = [3, 2, 7, 6, 5, 4, 3, 2];

  if (numbers.some((n) => Number.isNaN(n))) return false;

  const sum = multipliers
    .map((multiplier, index) => numbers[index] * multiplier)
    .reduce((acc, val) => acc + val, 0);
  const control = 11 - (sum % 11);

  return (control === 11 ? 0 : control) === numbers[8];
}

export function extractLastName(name: string | null) {
  if (!name) return null;
  const splitNames = name.trim().split(' ');
  return [splitNames.pop(), splitNames.join(' ')];
}
