import { isValidDate } from "./date-utils";

const monthStrings = ['Jan', 'Feb', 'Mar', 'Apr', 'Mai', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Des'];

const localeCompare = new Intl.Collator('nb');
const localeNumber = new Intl.NumberFormat('nb');
const localeCurrency = new Intl.NumberFormat('nb', {
  currency: 'NOK',
  style: 'currency',
});
const localeDate = new Intl.DateTimeFormat('nb', {
  month: 'short',
  day: 'numeric',
  year: 'numeric',
})
const localeDateShort = new Intl.DateTimeFormat('nb', {
  month: 'numeric',
  day: 'numeric',
  year: 'numeric',
})
const localTime = new Intl.DateTimeFormat('nb', {
  hour: '2-digit',
  minute: '2-digit',
})
const localTimeDetailed = new Intl.DateTimeFormat('nb', {
  hour: '2-digit',
  minute: '2-digit',
  second: '2-digit',
})

// export const meterFormater = new Intl.NumberFormat('no-nb', {
//   unitDisplay: 'short',
//   style: 'unit',
//   unit: 'meter',
//   maximumFractionDigits: 2,
// })

// export const cmFormater = new Intl.NumberFormat('no-nb', {
//   unitDisplay: 'short',
//   style: 'unit',
//   unit: 'centimeter',
//   maximumFractionDigits: 1,
// })

export function compare(a, b) {
  return localeCompare.compare(a, b);
}

export function number(n) {
  if (!n) return;
  return localeNumber.format(n);
}

export function currency(n) {
  if (!n) return;
  return localeCurrency.format(n);
}

export function toDistance(meters: number) {
  return meters > 1
    ? `${Math.ceil(meters * 100) / 100} m` // 2 decimals
    : `${Math.ceil(meters * 1000) / 10} cm` // 1 decimals
    // ? meterFormater.format(meters)
    // : cmFormater.format(meters * 100)
}

// This is a temporary function since old date function gives yyyy Mmm dd
// Implemented 02. aug 2021
export function date(dateString: any): string {
  if (!dateString || !isValidDate(dateString)) return '';
  const d = new Date(dateString);
  const day = d.getDate();
  const month = monthStrings[d.getMonth()];
  const year = d.getFullYear();
  return `${day}. ${month} ${year}`;
}

export function date2(dateString, short = false) {
  if (!dateString || !isValidDate(dateString)) return;
  return (short ? localeDateShort : localeDate).format(new Date(dateString));
}

export function time(dateString, includeSeconds = false) {
  if (!dateString || !isValidDate(dateString)) return;
  if (includeSeconds) return localTimeDetailed.format(new Date(dateString));
  return localTime.format(new Date(dateString));
}

export function localISODate(dateString) {
  if (!dateString) return;
  const date = new Date(dateString);

  const year = String(date.getFullYear());
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');

  const offset = Math.abs(date.getTimezoneOffset());
  const timezoneHour = String(Math.floor(offset / 60)).padStart(2, '0');
  const timezoneminute = String(offset % 60).padStart(2, '0');
  const timezone = `${date.getTimezoneOffset() < 0 ? '+' : '-'}${timezoneHour}:${timezoneminute}`;

  return `${year}-${month}-${day}T${hours}:${minutes}${timezone}`;
};

export function daysAgo(originalDate) {
  const targetDate = new Date(originalDate);
  const todaysDate = new Date();
  targetDate.setHours(0,0,0,0);
  todaysDate.setHours(0,0,0,0);

  const days = 24 * 60 * 60 * 1000;
  //@ts-expect-error
  const daysAgo = Math.floor((todaysDate - targetDate)/days);

  if (daysAgo > 6) return date(originalDate);
  if (daysAgo < 1) return 'I dag';
  return `${daysAgo} dag${daysAgo > 1 ? 'er' : ''} siden`;
}

export function formatHours(value: any): string {
    return value ? String(value).replace('.', ',') : value;
}