import { ref, watch } from 'vue';
import { PartnerPermissions } from '/@shared/types/ids';

const storage = 'goldPartners';

function getStorageItem() {
  const s = localStorage.getItem(storage);
  const i: Set<number> = s ? new Set(JSON.parse(s)) : new Set();
  return i;
}

function setStorageItem(i: any) {
  localStorage.setItem(storage, JSON.stringify([...i]));
}

export function clearGoldPartnerStorage() {
  localStorage.removeItem(storage);
}

export const goldPartners = ref(getStorageItem());

watch(goldPartners, (value) => {
  setStorageItem(value);
});

// use this only if store/partner/hasPartnerPermission cannot be applied
export function hasTenantPermissions(permissionList: Array<string>, permissions: Array<string>) {
  return permissions.some((p) =>
    [...permissionList, PartnerPermissions.DepartmentPartner].includes(p),
  );
}
