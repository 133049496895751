import { ref } from 'vue';
import { useDatabase } from '/@composables';
import { guid } from '/@tools/guid';
import { api } from '/@tools/api';
import { useUserStore } from '/@features/user';
import { log } from '/@plugins/log';
import { sub } from 'date-fns';

type QueueItemPost = {
  title: string;
  guid?: string;
  url: string;
  method: 'post' | 'put' | 'delete';
  data?: object;
  file?: {
    arrayBuffer: ArrayBuffer;
    filename: string;
    type: string;
  };

  onBefore?: () => unknown;
  onSuccess?: (data: any) => unknown;
  onError?: (err: any) => unknown;
};

type QueueItemMetadata = {
  guid: string;
  createdAt: Date;
  createdBy: string;
  status: 'ready' | 'running' | 'error' | 'done';
  errorMessage: Error | null;
  errorCount: number;
  lastRunAt: Date | null;
};

export type QueueItemFull = QueueItemPost & QueueItemMetadata;

function createQueueItem(post: QueueItemPost): QueueItemFull {
  return {
    ...post,
    guid: post.guid || guid(),
    createdAt: new Date(),
    createdBy: useUserStore().user.name,
    status: 'ready',
    errorMessage: null,
    errorCount: 0,
    lastRunAt: null,
  };
}

const items = ref(new Map<string, QueueItemFull>());
const running = ref(false);
const paused = ref(false);

const db = useDatabase();
const table = 'queue';

let resolveInitialize: () => void;
const awaitInitialize = new Promise<void>((resolve) => (resolveInitialize = resolve));

async function clearOld() {
  const items = await db.get('queue');

  const oldItems = items.filter((i) => i.createdAt < sub(new Date(), { months: 1 }));

  try {
    await Promise.all(oldItems.map((i) => db.remove('queue', i.guid)));
    if (oldItems.length) log.info(`[queue] successfully removed ${oldItems.length} items`);
  } catch {
    log.error(`[queue] failed to remove ${oldItems.length} items`);
  }
}

async function initialize() {
  await clearOld();

  const dbItems = await db.get(table);

  items.value = new Map(dbItems.map((i) => [i.guid, i]));

  if ([...items.value].some(([k, v]) => ['ready', 'error'].includes(v.status))) {
    log.info(
      `[queue] initialized with {readyCount} ready and {errorCount} error`,
      [...items.value.values()].filter((i) => i.status === 'ready').length,
      [...items.value.values()].filter((i) => i.status === 'error').length,
    );
  }

  resolveInitialize();
}

initialize();

function toFile(i: ArrayBuffer, filename: string, type: string) {
  const blob = new Blob([i], { type });
  return new File([blob], filename, { type });
}

function mapEndpointData(item: QueueItemFull) {
  const formData = new FormData();

  if (item.file) {
    formData.append('file', toFile(item.file.arrayBuffer, item.file.filename, item.file.type));

    if (!item.data) return;

    Object.entries(item.data).forEach(([key, value]) => {
      formData.append(key, value);
    });
  }

  return item.file ? formData : item.data;
}

function mapDatabaseData(item: QueueItemFull) {
  return { ...item, onBefore: undefined, onSuccess: undefined, onError: undefined };
}

export function useQueue() {
  function add(item: QueueItemPost) {
    const itemFull = createQueueItem(item);

    items.value.set(itemFull.guid, itemFull);

    db.add(table, itemFull.guid, mapDatabaseData(itemFull));

    log.info('[queue] item added: {itemGuid} {item}', item.guid, JSON.stringify(item));

    if (!running.value) run();
  }

  function retry(itemGuid: string) {
    const item = items.value.get(itemGuid);
    if (!item) return;
    item.status = 'ready';
  }

  function remove(itemGuid: string) {
    items.value.delete(itemGuid);
    db.remove(table, itemGuid);
    log.info('[queue] item removed: {itemGuid}', itemGuid);
  }

  async function run(): Promise<unknown> {
    log.info('[queue] starting run');

    if (paused.value) return log.info('[queue] is paused');
    if (!navigator.onLine) return log.info('[queue] is offline');

    await awaitInitialize;

    running.value = true;

    const item = [...items.value.values()].filter((item) => {
      const status = !['done', 'error'].includes(item.status);

      return status;
    })[0];

    if (!item) {
      running.value = false;
      return log.info('[queue] is empty', items.value);
    }

    item.status = 'running';

    await item.onBefore?.();

    log.info('[queue] trying {itemGuid}', item.guid);

    return api[item.method](item.url, mapEndpointData(item))
      .then(({ data }) => {
        log.info('[queue] run success: {itemGuid}', item.guid);
        item.status = 'done';
        return item.onSuccess?.(data);
      })
      .catch((err) => {
        log.error('[queue] run error: {itemGuid}', item.guid);
        item.status = 'error';
        item.errorMessage = new Error(err);
        item.errorCount++;
        return item.onError?.(err);
      })
      .finally(() => {
        item.lastRunAt = new Date();
        db.add(table, item.guid, mapDatabaseData(item));
        return run();
      });
  }

  function pause() {
    paused.value = true;
  }

  function unPause() {
    paused.value = false;
  }

  async function clear() {
    const dbItems = await db.get('queue');
    const removals = dbItems.filter((i) => ['done'].includes(i.status));
    removals.forEach((i) => {
      db.remove('queue', i.guid);
      items.value.delete(i.guid);
    });
  }

  return { items, running, paused, add, retry, remove, run, pause, unPause, clear };
}
