/*
 * OPT "OPTIONS"
 * Opt is a common interface for working with big sortable data
 *
 * See @shared/tools/list.ts for helper functions
 *
 * Examples:
 * - List of users
 * - List of projects
 * - Map of projects
 */


 // DEPRICATED: ListOptions
 export interface ListOptions {
  sortDesc: boolean,
  sortBy: string,
  search: string,
  filter: { [key: string]: string },
  pFilter: Array<[number, string]>,
  offset: number,
  limit: number,
  partnerId: number,
  columns: Array<number>,
}

export type ColumnId = number | string;

export interface OptColumnFilterListItem {
  name: string,
  count: number | null,
  color?: string,
  subtitle?: string,
  favorite?: boolean,
  sortIndex?: number,
}
export interface OptColumnOptionListItem {
  name: string,
  color?: string,
  favorite?: boolean,
  sortIndex?: number,
}

export interface OptColumn {
  id: ColumnId,

  /** Descriptive name of column */
  name: string,

  /** Used to group multiple columns together */
  category?: string,

  /** Key used for sorting results */
  sortKey?: string,

  /** Key used for filtering results and counting filter values */
  filterKey?: string | number,

  /** Default value for the filter */
  defaultFilterValue?: string | number,

  /** Fetch a list of possible/suggested values */
  getFilterList?: (args: { column: OptColumn, columns: OptColumn[], opt: Opt }) => Promise<Map<string | number, OptColumnFilterListItem>>,

  /** If only values from `getFilterList` can be used */
  filterListOnly?: boolean,

  /** If multiple values can be used on the same filter */
  filterMultiple?: boolean,

  /** Method to check if the filter should be hidden to ther user */
  isHidden?: (args: { columns: OptColumn[], opt: Opt }) => boolean,

  infoBlocks?: Array<{
    color: string,
    icon: string,
    title: string,
    description: string,
  }>,

  /** If values should be mapped to something else, when displayed to the user */
  mapValues?: (value: string | number, dependencies: any[]) => string,

  /** When selecting a value */
  getOptions?: (dependencies: any[], opt: Opt) => Map<null | string | number, OptColumnOptionListItem>,

  /** Any dependencies required to show the filter */
  dependencies?: any[] | (() => Promise<any>)[],

  /** Used internally when using the dependencies */
  _resolvedDependencies?: Array<any>,

  /** Ideal width, in `ch`, of the column value(s) */
  width?: number,

  /** If the column should start visible in scenarios where you can toggle column visiblity on/off */
  startOpen?: boolean, // Might be replaced with the `show` function

  /** When applicable, if the value of the row in this column can be edited */
  editable?: boolean,

  /** assign datatype if field is editable */
  editType?: 'date'
}

export enum OptType {
  List,
  Map,
}

export interface OptFilter {
  /** ID of column the filter applies to */
  columnId: ColumnId,

  /** Values used for filtering. Filtering multiple values is treated with OR operator. */
  values: Array<number | string>,

  /** When true, this filter should not be included in visible active filters and not be reset */
  isHidden?: boolean,

  // TODO: make it possible to say that a filter only applies to a certain source. Required when same opt is used for both Request and MO in single map.
}

export interface OptCommonParts<T = number> {
  /** Which tenant to request the data from. If the value is null, do not specify tenant (Usually the API makes this your own data) */
  // REMOVE ME
  tenantId: number | null,

  /** Filter items based on a single string. In VO this means customId and address. */
  search: string,

  /** Filter items to these properties. */
  filters: Array<OptFilter>,

  /** IDs of enabled columns */
  columns: Array<ColumnId>,

  /** IDs of marked rows/items. */
  marked: Array<T>,

  /** allow reset if opt is out of date */

  version?: number;

  createdAt: Date,
}


export interface ListOpt extends OptCommonParts {
  /** Type of interface */
  type: OptType.List,

  /** Sort items descending (true) or acending (false) */
  sortDesc: boolean,

  /** Sort items by property. Value depends on what the API-endpoint exposes. */
  sortBy: string,

  /** Offset the items for pagination. */
  offset: number,

  /** Limit results for pagination. */
  limit: number,
}

export interface MapOpt extends OptCommonParts<string> {
  /** Type of interface */
  type: OptType.Map,

  /** [Longitude, Latitude] where the center of the map is. */
  coordinates: [number, number],

  /** Map zoom level */
  zoom: number,

  /** disable reposition on GPS change */
  disableReposition: boolean,
}

export type Opt = ListOpt | MapOpt;

export type OptT<T> =
  T extends OptType.List ? ListOpt :
  T extends OptType.Map ? MapOpt :
  never;