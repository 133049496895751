//@ts-nocheck

import * as structuredLog from 'structured-log';
import seqSink from 'structured-log-seq-sink';
import config from '/@tools/config';
import { User } from '/@features/user/user.types';

// plugin that sends messages to seq
// https://github.com/structured-log/structured-log

const consoleLog = structuredLog.configure().writeTo(new structuredLog.ConsoleSink()).create();

const seqLog = structuredLog
  .configure()
  .writeTo(
    new structuredLog.BatchedSink(
      seqSink({
        url: config.seqUrl,
        apiKey: config.seqKey,
      }),
    ),
  )
  .enrich(() => {
    const user = JSON.parse(localStorage.getItem('currentUser') || 'null') as User | null;

    return {
      AppVersion: config.buildVersion,
      ...(user
        ? {
            UserId: user.id,
            User: user.email,
            TenantId: user.tenant.id,
            Tenant: user.tenant.name,
          }
        : {
            UserId: null,
            User: null,
            TenantId: null,
            Tenant: null,
          }),
      Location: location.href,
    };
  })
  .create();

export const log = config?.seqUrl == null ? consoleLog : seqLog;

// example usage:

// fatal | error | warn | info | debug | verbose

// log.info('this is a message with {Id} and {Name}', 1, 'Ola');

// will show the message where Id and Name are put in seq message + seq properties
