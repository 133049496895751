import Dexie, { type EntityTable } from 'dexie';
import { QueueItemFull } from './useQueue';
import { Property } from '/@types/properties';
import { User } from '/@features/user/user.types';
import { sub } from 'date-fns';
import { Project, ProjectType } from '/@features/project/project.types';
import { Checkpoint, CheckpointFile, Photoreport } from '/@features/photoreport/photoreport.types';

const db = new Dexie('dfDatabase') as Dexie & {
  user: EntityTable<User, 'id'>;
  projects: EntityTable<Project, 'id'>;
  projectProperties: EntityTable<Property, 'uniqueId'>;
  checklistTemplates: EntityTable<any, 'id'>;
  projectTypes: EntityTable<ProjectType, 'id'>;
  queue: EntityTable<QueueItemFull, 'guid'>;

  photoreports: EntityTable<Photoreport, 'guid'>;
  photoreportFiles: EntityTable<CheckpointFile, 'fileGuid'>;
  photoreportCheckpoints: EntityTable<Checkpoint>;
};

db.version(3).stores({
  user: 'id',
  projects: 'id',
  projectProperties: 'uniqueId',
  checklistTemplates: 'id',
  projectTypes: 'id',
  queue: 'guid, createdAt',
  photoreports: 'guid',
  photoreportFiles: 'fileGuid',
  photoreportCheckpoints: 'guid',
});

type Table = {
  user: User;
  projects: Project;
  projectProperties: Property;
  projectTypes: ProjectType;
  checklistTemplates: any;
  queue: QueueItemFull;
  photoreports: Photoreport;
  photoreportFiles: CheckpointFile;
  photoreportCheckpoints: Checkpoint;
};

export function useDatabase() {
  function get<T extends keyof Table>(table: T): Promise<Array<Table[T]>> {
    return db[table].toArray();
  }

  function add<T extends keyof Table>(table: T, id: number | string, item: Table[T]) {
    return db[table].put(Dexie.deepClone(item), id);
  }

  async function addBulk<T extends keyof Table>(table: T, items: Array<Table[T]>, clear = false) {
    if (clear) await db[table].clear();
    return Promise.all(items.map((i) => db[table].put(Dexie.deepClone(i), i.id)));
  }

  function remove(table: keyof Table, id: number | string) {
    return db[table].delete(id);
  }

  function removeOld(table: keyof Table) {
    const oneWeekAgo = sub(new Date(), { weeks: 1 });

    return db[table].where('createdAt').below(oneWeekAgo).delete();
  }

  function clear(table: keyof Table) {
    return db[table].clear();
  }

  return { get, add, addBulk, remove, removeOld, clear };
}
