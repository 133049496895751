import piexif from 'piexifjs';
import { guid } from '/@tools/guid';
import { apiBaseUrl } from '/@tools/config';
import { api, contentDisposition } from '/@tools/api';
import { mimeType } from '/@tools/images';
import { log } from '/@plugins/log';
import { sendError } from '/@plugins/sentry';
import { client } from '/@shared/plugins/auth0';

const { format } = new Intl.NumberFormat('nb', {
  maximumFractionDigits: 1,
});

export const filePath = (filename: string, queries: string[]) => {
  return `/files/${filename}?${queries ? queries.join('&') : ''}`;
};

export const fileSize = (size: number) => {
  if (size > 1000000000000) {
    return `${format(size / 1000000000000)} TB`;
  }

  if (size > 1000000000) {
    return `${format(size / 1000000000)} GB`;
  }

  if (size > 1000000) {
    return `${format(size / 1000000)} MB`;
  }

  if (size > 1000) {
    return `${format(size / 1000)} KB`;
  }

  return `${format(size)} Byte`;
};

export function fileArrayBuffer(file: File): Promise<ArrayBuffer> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = ({ target }) => resolve(target.result);
    reader.onerror = ({ target }) => reject(target.error);
    reader.onabort = (event) => reject(event);

    reader.readAsArrayBuffer(file);
  });
}

export async function canUseCamera() {
  try {
    if (!('permissions' in navigator)) return true;
    //@ts-expect-error camera not supported in all browsers
    if ((await navigator.permissions.query({ name: 'camera' })).state !== 'denied') return true;
    return false;
  } catch {
    return true;
  }
}

export const fileUploadEntry = (file: File, url: string, capture = false, clientGuid = guid()) =>
  new Promise<{ uploadUrl: string; guid: string | null; name: string; arrayBuffer: ArrayBuffer }>(
    (resolve) => {
      const fileReader = new FileReader();
      const didGenerateGuid = /\[guid\]/.test(url);
      const fileEntry = {
        name: file.name,
        size: file.size,
        type: mimeType(file),
      };

      log.info('File with guid {GUID} is being processed', clientGuid);

      fileReader.onload = async ({ target }) => {
        log.info('File with guid {GUID} was read', clientGuid);

        try {
          const result = target?.result as string;
          const mimeType = result.match(/[^:\s*]\w+\/[\w-+\d.]+(?=[;| ])/)?.[0];
          const uploadUrl = url.replace('[guid]', clientGuid);
          const blob = await fetch(result).then((res) => res.blob());
          const arrayBuffer = await new Response(blob).arrayBuffer();
          const queueEntry = {
            arrayBuffer,
            name: fileEntry.name,
            uploadUrl,
            guid: didGenerateGuid ? clientGuid : null,
          };

          if (capture && mimeType != null && /.jpeg|.jpg/.test(mimeType)) {
            log.info('[EXIF] Reading');
            const exif = piexif.load(target?.result);

            if (!(piexif.GPSIFD.GPSLatitude in exif.GPS)) {
              log.info('Setting EXIF GPS for file with guid {GUID}', clientGuid);
              const setGPS = (): Promise<void> =>
                new Promise((resolve, reject) => {
                  log.info('[EXIF] Getting coordinates');
                  navigator.geolocation.getCurrentPosition(
                    async ({ coords }) => {
                      const lat = coords.latitude;
                      const lng = coords.longitude;

                      const GPS = {
                        [piexif.GPSIFD.GPSLatitudeRef]: lat < 0 ? 'S' : 'N',
                        [piexif.GPSIFD.GPSLatitude]: piexif.GPSHelper.degToDmsRational(lat),
                        [piexif.GPSIFD.GPSLongitudeRef]: lng < 0 ? 'W' : 'E',
                        [piexif.GPSIFD.GPSLongitude]: piexif.GPSHelper.degToDmsRational(lng),
                      };

                      const dataUrl = piexif.insert(
                        piexif.dump({ GPS, '0th': exif['0th'] }),
                        target?.result,
                      );
                      const blob = await fetch(dataUrl).then((res) => res.blob());

                      queueEntry.arrayBuffer = await new Response(blob).arrayBuffer();

                      log.info('[EXIF] New coordinates written');
                      log.info('File with guid {GUID} got new EXIF GPS {clientGuid}', clientGuid);

                      resolve();
                    },
                    (error) => {
                      reject(error);
                    },
                    {
                      timeout: 10000,
                      maximumAge: 60000,
                    },
                  );
                });

              await setGPS().catch((error) => {
                log.error('[EXIF] Error setting coordinates');
                console.error(error);
                sendError(error, { uploadUrl, mimeType });
              });
            } else {
              log.info('[EXIF] Coordinates already present');
            }
          }

          resolve(queueEntry);
        } catch (error) {
          sendError(error, { clientGuid, fileEntry });
        }
      };

      fileReader.readAsDataURL(file);
    },
  );

export async function downloadFile(url: string, filename?: string) {
  const isExternal = url.startsWith('https://');
  const token = isExternal ? null : await client?.getTokenSilently().catch(() => null);

  fetch(isExternal ? url : `${apiBaseUrl}/${url}`, {
    method: 'get',
    headers: new Headers({
      'Content-Type': 'application/json',
      Accept: 'application/pdf',
      ...(token ? { Authorization: `Bearer ${token}` } : {}),
    }),
  })
    .then(async (response) => {
      return [
        await response.blob(),
        filename ?? contentDisposition(response.headers.get('Content-Disposition')).filename(),
      ];
    })
    .then(([blob, filename]) => {
      const url = URL.createObjectURL(blob as Blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `${filename}`;
      document.body.appendChild(a);
      a.click();

      setTimeout(() => {
        URL.revokeObjectURL(url);
        document.body.removeChild(a);
      }, 100);
    });
}

export function downloadImage(guid: string, filename: string) {
  return downloadFile(`/files/${guid}`, filename);
}

export function openOriginal(guid: string) {
  return api.get(`/files/${guid}/uri`).then(({ data }) => {
    if (data?.uri != null) {
      open(data.uri, '_blank');
    }
  });
}
