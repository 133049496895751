import { TenantFeatures } from '/@features/tenant/tenant.types';
import { viewportMapString, viewportString } from './config';
import { Modules, Role, Tenants } from '/@shared/types/ids';
import { RolePerm, User } from '/@features/user/user.types';

export function validUrl(url) {
  const a = /^(?:http(s)?:\/\/)/;
  const b = /www./;
  let newUrl = url;

  if (b.test(newUrl) === false) {
    newUrl = `www.${newUrl}`;
  }

  if (a.test(newUrl) === false) {
    newUrl = `https://${newUrl}`;
  }
  return newUrl;
}

export function initials(name) {
  return name
    ?.split(' ')
    .map((x) => x.substr(0, 1))
    .join('')
    .toUpperCase();
}

// convert municipality
export function convertMunicipality(municipality) {
  if (municipality) {
    return municipality
      .toLowerCase()
      .replace(/[a-zøæå]+/gi, ([first, ...rest]) => first.toUpperCase() + rest.join(''));
  }
  return municipality;
}

export function deprecated(element) {
  console.warn(
    `<${element.$parent.$options.name}> -> <${element.$options.name}> : Warning: deprecated`,
  );
}

export function isInViewport(element) {
  const bounding = element.getBoundingClientRect();

  return (
    bounding.top >= 0 &&
    bounding.left >= 0 &&
    bounding.right <= (window.innerWidth || document.documentElement.clientWidth) &&
    bounding.bottom <= (window.innerHeight || document.documentElement.clientHeight)
  );
}

export function shortenText(text, maxLength = 15) {
  if (text && text.length > maxLength) {
    return `${text.substring(0, maxLength)}...`;
  }
  return text;
}

export function sortArray(array) {
  return array.sort((a, b) => (a[1].name < b[1].name ? -1 : a[1].name > b[1].name ? 1 : 0));
}

export function sortIndex(a: number | null, b: number | null) {
  return a === null ? 1 : b === null ? -1 : a - b;
}

export function search(query: string, target: string) {
  return target?.toLowerCase().includes(query.toLowerCase());
}

export function searchMultiple(query: string, obj: object, arr: Array<string>) {
  return arr.some((prop) => obj[prop]?.toLowerCase().includes(query.toLowerCase()));
}

export function regexEscape(regex: TemplateStringsArray, ...variables: Array<any>) {
  return new RegExp(
    regex.reduce(
      (acc, value, index) =>
        [acc, value, variables[index]?.replace(/[.*+\-?^${}()|[\]\\]/g, '\\$&')].join(''),
      '',
    ),
  );
}

// Kelly's 22 colours of maximum contrast
export const colors = [
  // '#F2F3F4',
  // '#222222',
  '#875692',
  '#F38400',
  '#A1CAF1',
  '#BE0032',
  '#C2B280',
  '#848482',
  '#008856',
  '#E68FAC',
  '#0067A5',
  '#F99379',
  '#604E97',
  '#F6A600',
  '#B3446C',
  '#DCD300',
  '#882D17',
  '#8DB600',
  '#654522',
  '#E25822',
  '#2B3D26',
  '#F3C300',
];

export const statusColors = [
  '#1abc9c',
  '#2ecc71',
  '#3498db',
  '#9b59b6',
  '#e91e63',
  '#f1c40f',
  '#e67e22',
  '#e74c3c',
  '#95a5a6',
  '#607d8b',
];

export const EMPTY_STRING = '#EMPTY';
export const EMPTY_ID = -100;

export function isEmptyValue(value) {
  return [EMPTY_STRING, EMPTY_ID].includes(value);
}

export function diag(i) {
  console.log(i);
  return i;
}

export function testApiResults(chance = 50) {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      const n = Math.floor(Math.random() * 100 + 1);
      console.log(n <= chance ? `success` : 'failed', chance, n);
      return n <= chance ? resolve('success') : reject('failed');
    }, 2000);
  });
}

export function isTenant(user: any, tenantIds: Array<Tenants>) {
  return tenantIds.includes(user.tenant.id);
}

export function hasRole(user: { roles: Array<{ id: number }> }, roleIds: Array<Role>): boolean {
  if (user.roles.some(({ id }) => id === Role.AppOwner)) return true;
  return user.roles.some(({ id }) => roleIds.includes(id));
}

export function hasModule(
  user: { modules: { id: number }[]; hiddenModuleIds: number[] },
  moduleIds: Array<Modules>,
) {
  return user.modules.some(
    ({ id }) => moduleIds.includes(id) && !user.hiddenModuleIds.includes(id),
  );
}

export function hasTenantFeature(
  tenant: { features: TenantFeatures[] },
  features: Array<TenantFeatures>,
) {
  return tenant.features.some((f) => features.includes(f));
}

export function hasRolePerm(user: User, permission: RolePerm) {
  return user.permissions.includes(permission);
}

// identify and separate urls out of text to create urls
export function convertURLToShorthand(string): any {
  if (typeof string !== 'string') return;
  if (string.includes('https://') || string.includes('www.')) {
    return string
      .split(' ')
      .map((s) => {
        if (s.includes('https://') || s.includes('www.')) {
          const urlArray = s.replace('https://', '').replace('www.', '').split('/');
          return `${urlArray.slice(0, 2).join('/')}...`;
        }
        return s;
      })
      .join(' ');
  }
  return string;
}

interface TextObject {
  text: string;
  type: string;
  url: string | null;
}

export function convertStringToTextArray(string: string): Array<TextObject> {
  if (!string) return [];

  return string
    .split(' ')
    .map((text) => ({ text, type: 'text', url: null }))
    .reduce((acc, cv) => {
      if (!cv.text.includes('www.') || !cv.text.includes('www.')) {
        const lastAcc = acc[acc.length - 1];

        if (lastAcc?.type === 'text') {
          lastAcc.text = lastAcc.text.concat(' ', cv.text);
        } else {
          acc.push(cv);
        }
      } else {
        acc.push(createURLObject(cv));
      }

      return acc;
    }, []);
}

function createURLObject(obj): TextObject {
  obj.type = 'url';
  obj.url = obj.text.includes('https://') ? obj.text : `https://${obj.text}`;
  const urlArray = obj.text.replace('https://', '').replace('www.', '').split('/');
  obj.text = `${urlArray.slice(0, 2).join('/')}...`;
  return obj;
}

export function isTest(local = false) {
  const href = window.location.href;
  return href.includes('.test') || (local ? href.includes('localhost') : false);
}

export function isUAT() {
  return window.location.href.includes('.uat');
}

export function getTimerUrl() {
  const subdomain = isTest(true) ? 'test.' : isUAT() ? 'uat.' : '';
  return `https://timer2.${subdomain}dokflyt.no`;
}

export function getBackofficeUrl() {
  const subdomain = isTest(true) ? 'dev' : isUAT() ? 'backoffice.uat.' : '';
  return `https://${subdomain}app.dokflyt.no`;
}

export function updateViewport({ isMap = false }) {
  document.head
    .querySelector('meta[name="viewport"]')
    ?.setAttribute('content', isMap ? viewportMapString : viewportString);
}

export function shortenArray(array: string[], length = 3): string[] {
  const a = array.slice(0, length);

  if (array.length > length) {
    a.push(`+${array.length - length}`);
  }

  return a;
}

interface DependencyItem {
  dependencies?: Array<Map<any, any> | (() => Promise<Map<any, any>>)>;
  _resolvedDependencies?: Map<any, any>[];
}

/** Load all dependencies */
export function initDependencies<T>(items: Array<DependencyItem & T>): Promise<T[]> {
  const loadMapper = items.map(async (item) => {
    if (!item.dependencies?.length) return;
    item._resolvedDependencies = await Promise.all(
      item.dependencies.map((maybeFn) => (typeof maybeFn === 'function' ? maybeFn() : maybeFn)),
    );
  });

  return Promise.all(loadMapper).then(() => items);
}

export function exitFullscreen() {
  if (document.fullscreenElement) {
    document.exitFullscreen();
    //@ts-expect-error safari vendor
    document.webkitExitFullscreen?.();
  }
}

export function launchIntoFullscreen(el: HTMLElement) {
  if (document.fullscreenElement) return;

  if (el.requestFullscreen) el.requestFullscreen();
  //@ts-expect-error safari vendor
  if (el.webkitRequestFullscreen) el.webkitRequestFullscreen();
}

export function isSafari() {
  const userAgent = navigator.userAgent;
  const chromeAgent = userAgent.indexOf('Chrome') > -1;
  const safariAgent = userAgent.indexOf('Safari') > -1;

  return chromeAgent && safariAgent ? false : true;
}

export function isIos() {
  const agent = navigator.userAgent;
  return /iPad|iPhone|iPod/.test(agent);
}

export const projectModules = [
  Modules.Energi2,
  Modules.Veilys,
  Modules.Byggestrom,
  Modules.Ett,
  Modules.KommuneOppdrag,
  Modules.Elektro,
  Modules.Telekom,
  Modules.Jernbane,
  Modules.Innmåling,
];

export function getProjectUrl(modules: number[]) {
  if (modules.includes(Modules.Energi2)) return 'energi';
  if (modules.includes(Modules.Veilys)) return 'veilys';
  if (modules.includes(Modules.Byggestrom)) return 'byggestrom';
  if (modules.includes(Modules.Ett)) return 'ett';
  if (modules.includes(Modules.KommuneOppdrag)) return 'kommuneoppdrag';
  if (modules.includes(Modules.Elektro)) return 'elektro';
  if (modules.includes(Modules.Telekom)) return 'telekom';
  if (modules.includes(Modules.Jernbane)) return 'jernbane';
  if (modules.includes(Modules.Innmåling)) return 'innmaling';
  if (modules.includes(Modules.VaktOgBeredskap)) return 'beredskap';
  if (modules.includes(Modules.KommunePartner)) return 'kommunepartner';
  if (modules.includes(Modules.Partner)) return 'partner';
  return null;
}
