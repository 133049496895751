import { Auth0Client, createAuth0Client } from '@auth0/auth0-spa-js';
import { sendError } from '/@plugins/sentry';
import { useNotifications } from '/@composables';

export let client: Auth0Client;

export function createClient() {
  createAuth0Client({
    domain: import.meta.env.VITE_AUTH0_DOMAIN,
    clientId: import.meta.env.VITE_AUTH0_CLIENTID,
    authorizationParams: {
      audience: import.meta.env.VITE_AUTH0_AUDIENCE,
      redirect_uri: `${location.origin}/auth0-callback`,
    },
    useRefreshTokens: true,
    cacheLocation: 'localstorage',
  })
    .then((auth0client) => {
      client = auth0client;
    })
    .catch((error) => {
      document.body.textContent = 'Autentiserer ...';
      sendError(error, { error });

      setTimeout(() => {
        location.reload();
      }, 4000);
    });
}

export function authenticate(fn: () => void) {
  if (client == null) {
    setTimeout(() => authenticate(fn), 10);
    return;
  }

  client.isAuthenticated().then((value) => {
    if (!value) return login();
    fn();
  });
}

export function login() {
  client.loginWithRedirect({ appState: { target: location.href } });
}

export function logout() {
  if (client == null) {
    setTimeout(() => logout, 10);
    return;
  }

  client?.logout({
    logoutParams: {
      returnTo: `${import.meta.env.VITE_SIGNOUT_URL as string}?from=${location.origin}`,
    },
  });

  useNotifications().logoutOneSignal();
}
