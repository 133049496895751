import { StorageKeys, getItem, setItem, removeItem } from '/@shared/tools/storage';

const settings = { useUser: true };
const departmentsKey = StorageKeys.DepartmentIds;

// force check if cmp is hidden behind a v-if
export function setupDepartment(user: { departmentId: number | null }) {
  if (user.departmentId) {
    if (!getItem(departmentsKey, settings)) setItem(departmentsKey, [user.departmentId], settings);
  } else removeItem(departmentsKey, settings);
}

// use in api endpoints
export function addDepartmentQueries(query: URLSearchParams) {
  const storedDepartmentIds = getItem(StorageKeys.DepartmentIds, { useUser: true }) || [];

  storedDepartmentIds.forEach((e) => {
    query.append('departmentIds', e);
  });

  return query;
}
